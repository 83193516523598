<template>
  <div style="position:relative" class="leaflet-slider-container">
    <swiper :options="swiperOptions" ref="leafletSwiperRef">
      <swiper-slide
        class="h-100"
        v-for="leaflet in leaflets"
        :key="leaflet.leafletId"
      >
        <LeafletCard :leaflet="leaflet" :small="small" />
      </swiper-slide>
    </swiper>
    <div id="leaflet-slider-next" class="swiper-button-next"></div>
    <div id="leaflet-slider-prev" class="swiper-button-prev"></div>
  </div>
</template>
<style lang="scss">
.leaflet-slider-container {
  .leaflet-card {
    max-height: 280px;
    width: 400px;
    .leaflet-img {
      max-width: 100%;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .swiper-slide {
      width: auto;
      height: auto;
      .leaflet-card.v-card {
        height: 100%;
        overflow: hidden;
      }
    }
  }
}
</style>

<script>
import LeafletCard from "@/components/leaflet/LeafletCard.vue";

import leafletService from "~/service/leafletService.js";

export default {
  name: "LeafletsSlider",
  components: { LeafletCard },
  props: {
    parentWarehouseId: { type: Number, required: false },
    small: { type: Boolean, default: false }
  },
  data() {
    return {
      leaflets: [],
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 20,
        autoplay: false,
        navigation: {
          prevEl: `#leaflet-slider-prev`,
          nextEl: `#leaflet-slider-next`
        }
      }
    };
  },
  async mounted() {
    this.leaflets = await leafletService.search({
      parent_warehouse_id: this.parentWarehouseId
    });
  }
};
</script>
