import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n";
import "@/scss/_fonts.scss";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    //disable: true,
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        logo: {
          default: "/logo/logo.svg",
          mobile: "/logo/logo-mobile.svg"
        },
        default: {
          base: "#4A4A49"
        },
        primary: {
          base: "#13A538",
          lighten1: "#24AC40",
          lighten2: "#46BA50",
          lighten3: "#68C860",
          lighten4: "#8AD670",
          lighten5: "#ACE480",
          darken1: "#007B3D"
        },
        secondary: {
          base: "#D51317",
          lighten1: "#EC2A2E",
          lighten2: "#F05558",
          lighten3: "#F47F82",
          lighten4: "#F7AAAC",
          darken1: "#D41217"
        },
        accent: {
          base: "#FDC300",
          lighten1: "#F8DD64"
        },
        white: { base: "#FFFFFF" },
        black: { base: "#142A14" },
        grey: {
          base: "#D4D4D4",
          lighten1: "#E6E2DC",
          lighten2: "#F4F2F0",
          darken1: "#4A4A49"
        },
        green: { base: "#afca0b", lighten1: "#95D7A5", lighten2: "#D0EDD7" },
        error: { base: "#B00720", lighten1: "#EFCDD2" },
        warning: {
          base: "#F8DD64",
          lighten1: "#FEF8E0"
        },
        success: {
          base: "#91C46C",
          lighten1: "#E9F3E1"
        },
        info: {
          base: "#8EC7D2",
          lighten1: "#E8F4F6"
        }
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    values: {
      // cardArrow: "icon-card-arrow",
      // clock: "icon-clock"
      // delete: "icon-delete",
      arrowBack: "icon-arrow-back",
      arrowDownward: "icon-arrow-downward",
      arrowForward: "icon-arrow-forward",
      arrowTopLeft: "icon-arrow-top-left",
      arrowUpward: "icon-arrow-upward",
      cancel: "icon-close",
      cart: "icon-cart",
      checkboxIndeterminate: "icon-checkbox-indeterminate",
      checkboxOff: "icon-checkbox-off",
      checkboxOn: "icon-checkbox-on",
      clear: "icon-close",
      close: "icon-close",
      complete: "icon-complete",
      creditcard: "icon-creditcard",
      day: "icon-day",
      delete: "icon-garbage",
      delimiter: "icon-delimiter", // for carousel
      delivery: "icon-delivery",
      dots: "icon-dots",
      dropdown: "icon-dropdown",
      edit: "icon-edit",
      editcalendar: "icon-edit-calendar",
      error: "icon-warning",
      euro: "icon-euro",
      expand: "icon-expand",
      expandLess: "icon-expand-less",
      eye: "icon-eye",
      eyeOff: "icon-eye-off",
      file: "icon-file",
      filter: "icon-filter",
      first: "icon-first",
      geolocate: "icon-location",
      heart: "icon-heart",
      heartfull: "icon-heartfull",
      home: "icon-home",
      info: "icon-info",
      last: "icon-last",
      like: "icon-like",
      loading: "...",
      location: "icon-location",
      locationDashboard: "icon-location",
      locker: "icon-locker",
      rapid: "icon-bicycle",
      logout: "icon-logout",
      menu: "icon-menu",
      // menuEcommerce: "icon-menu-ecommerce",
      microphone: "icon-microphone",
      minus: "icon-minus",
      mood: "...",
      next: "icon-next",
      night: "icon-night",
      noPackage: "...",
      orders: "icon-orders",
      package: "...",
      percent: "icon-percent",
      pickup: "...",
      plus: "icon-add",
      power: "icon-power",
      prev: "icon-prev",
      print: "icon-print",
      products: "...",
      profile: "icon-profile",
      profileFull: "icon-profile-full",
      profileDashboard: "icon-profile",
      qrCode: "icon-qr-code",
      barcode: "icon-barcode-scan",
      radioOff: "icon-radio-unchecked",
      radioOn: "icon-radio-checked",
      ratingEmpty: "icon-rating-full",
      ratingFull: "icon-rating-full",
      ratingHalf: "icon-rating-half",
      receipt: "icon-receipt",
      search: "icon-search",
      fastSearch: "icon-fast-search",
      sort: "icon-ordina-by",
      storefront: "icon-storefront",
      subgroup: "icon-expand",
      success: "icon-complete",
      tag: "icon-tag",
      unfold: "icon-unfold",
      warning: "icon-warning",
      services: "icon-services",
      gift: "icon-gift",
      regulation: "icon-regulation",
      pack: "icon-pack",
      box: "icon-box",
      starFull: "icon-rating",
      starHalf: "icon-rating-half",
      star: "icon-rating-empty",
      favourite: "icon-heart",
      work: "icon-work",
      copy: "icon-copy",
      googlePlus: "icon-G",
      youtube: "icon-youtube",
      settings: "icon-settings",
      recipe: "icon-recipe",
      timer: "icon-timer",
      people: "icon-people",
      share: "icon-share",
      groups: "icon-groups",
      alarm: "icon-alarm",
      map: "icon-map",
      schedule: "icon-schedule",
      facebook: "icon-facebook",
      twitter: "icon-twitter",
      whatsapp: "icon-whatsapp",
      linkedin: "icon-linkedin",
      email: "icon-email",
      mail: "icon-mail",
      folder: "icon-folder",
      doc: "icon-doc",
      fullscreenExit: "icon-fullscreen-exit",
      fullscreen: "icon-fullscreen",
      download: "icon-download",
      eugenio: "icon-eugenio",
      wifi: "icon-connection",
      camioncino: "icon-camioncino"
    }
  }
});
