import { mapState } from "vuex";
import get from "lodash/get";

export default {
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    money() {
      let value = this.cart.user.userPointsCounters.filter(
        points => points.codInt == "PM2"
      );
      return get(value, "[0].value");
    },
    points() {
      let value;
      if (this.cart && this.cart.user && this.cart.user.userPointsCounters) {
        value = this.cart.user.userPointsCounters.filter(
          points => points.codInt == "PTS"
        );
      } else {
        return null;
      }
      let points = get(value, "[0].value", 0);
      return points < 0 ? "-" : points;
    },
    moneyCheckout() {
      let value = this.cart.user.userPointsCounters.filter(
        points => points.codInt == "PME"
      );
      return get(value, "[0].value");
    },
    newCollectionPoints() {
      let value = this.cart.user.userPointsCounters.filter(
        points => points.codInt == "PTN"
      );
      return get(value, "[0].value");
    }
  }
};
