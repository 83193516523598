import deepMerge from "lodash/merge";
import clone from "lodash/cloneDeep";
import get from "lodash/get";
import mapKeys from "lodash/mapKeys";
// import has from "lodash/has";

export default {
  computed: {
    optionsName() {
      return this.categoryBlockName
        ? this.categoryBlockName
        : this.$options.name;
    },
    swiperOption() {
      let keyMap = {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1264,
        xl: 1900
      };

      let jsonConfig = deepMerge(
        this.swiperDefaultOption,
        clone(
          get(
            this.config,
            `metaData.categoryblocktype_${this.optionsName}.JSON_CONFIG`
          )
        )
      );

      let convertedBreakpoints = mapKeys(jsonConfig.breakpoints, function(
        // eslint-disable-next-line no-unused-vars
        value,
        key
      ) {
        return keyMap[key];
      });
      if (convertedBreakpoints) jsonConfig.breakpoints = convertedBreakpoints;

      return jsonConfig;
    },
    style() {
      let style = {};
      let backgroundColor = get(
        this.config,
        `metaData.categoryblocktype_${this.optionsName}.BACKGROUND_COLOR`
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      let backgroundImage;
      if (this.$vuetify.breakpoint.xsOnly) {
        backgroundImage = get(
          this.config,
          `metaData.categoryblocktype_${this.optionsName}.BACKGROUND_IMAGE_MOBILE.location`
        );
      }
      if (!backgroundImage) {
        backgroundImage = get(
          this.config,
          `metaData.categoryblocktype_${this.optionsName}.BACKGROUND_IMAGE.location`
        );
      }
      if (backgroundImage) {
        style.backgroundImage = `url("${backgroundImage}")`;
        let backgroundPosition =
          get(
            this.config,
            `metaData.categoryblocktype_${this.optionsName}.BACKGROUND_POSITION`
          ) || "top center";
        style.backgroundPosition = backgroundPosition;
        // if (
        //   has(
        //     this.config,
        //     `metaData.categoryblocktype_${this.optionsName}.TITLE`
        //   ) ||
        //   has(
        //     this.config,
        //     `metaData.categoryblocktype_${this.optionsName}.DESCRIPTION`
        //   )
        // ) {
        //   style.paddingTop = "180px";
        // }
      }
      style.paddingTop = get(
        this.config,
        `metaData.categoryblocktype_${this.optionsName}.BACKGROUND_PADDING`
      );

      return style;
    },
    modelName() {
      return get(
        this.config,
        `metaData.categoryblocktype_${this.optionsName}.MODE`,
        "ProposalImage"
      );
    }
  },
  methods: {
    getProposalModelName(proposal) {
      return get(
        proposal,
        "categoryProposalType.name",
        "ProposalCardVerticalResponsive"
      );
    }
  }
};
