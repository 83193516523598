<template>
  <div class="warehouse-green-card primary pa-4">
    <h3 v-if="!isDetail">
      {{ $t("favouriteWhBlock.favWhTitle") }}
    </h3>
    <p v-if="!isDetail">
      {{ warehouse.name }}
      <FavWarehouseSelector />
    </p>
    <h3 v-else class="mb-2">{{ warehouse.name }}</h3>
    <p>
      <v-icon class="pr-2">$location</v-icon>{{ warehouse.address.address1 }},{{
        warehouse.address.addressNumber
      }}
      {{ warehouse.address.postalcode }}
      {{ warehouse.address.city }}
      {{ warehouse.address.province }}
    </p>
    <p>
      <v-icon class="pr-2">$schedule</v-icon
      ><strong>{{ $t("favouriteWhBlock.favWhHours") }}</strong>
    </p>
    <p>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="3">
          <IsOpenRightNow
            :serviceHours="warehouse.serviceHours"
            color="#142a14"
            :outlined="true"
            :small="true"
          ></IsOpenRightNow>
        </v-col>
        <v-col cols="12" sm="12" md="9">
          <WarehouseHours :serviceHours="warehouse.serviceHours">
          </WarehouseHours>
        </v-col>
      </v-row>
    </p>
    <div class="d-flex flex-row justify-space-between">
      <div>
        {{ $t("favouriteWhBlock.favWhPhone") }} <br />
        <strong>
          <a
            class="warehouse-phone"
            :href="
              `tel:${$ebsn.meta(warehouse, 'metaData.warehouse_locator.PHONE')}`
            "
            >{{ $ebsn.meta(warehouse, "metaData.warehouse_locator.PHONE") }}</a
          >
        </strong>
      </div>
      <div>
        <v-btn
          x-small
          text
          :href="
            `https://www.google.it/maps/dir//${warehouse.name}@/${warehouse.address.latitude},${warehouse.address.longitude}`
          "
          target="_blank"
          class="calc-distnace-btn text-uppercase"
          >{{ $t("favouriteWhBlock.favWhCalcTrip") }}
          <v-icon class="pl-2">$map</v-icon></v-btn
        >
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.warehouse-green-card {
  height: 100%;
}
.warehouse-green-card,
.v-icon {
  color: var(--v-black-base);
}
.today-hours {
  color: initial !important;
}
.v-application a.warehouse-phone {
  color: var(--v-black-base);
  text-decoration: none;
}
.open-alert {
  padding: 2px 4px;
  font-size: 0.9rem;
  margin-top: 8px;
  border-width: 1px !important;
}
.calc-distnace-btn {
  color: var(--v-black-base);
}

.warehouse-services {
  background-color: #f4f2f0;
  .service-link {
    position: absolute;
    bottom: 0px;
    right: 0;
  }
}
</style>
<script>
import IsOpenRightNow from "@/components/wordpress/isOpenRightNow.vue";
import WarehouseHours from "@/components/warehouse/WarehouseHours.vue";
import FavWarehouseSelector from "./FavWarehouseSelector.vue";

export default {
  name: "WarehouseInfo",
  components: { IsOpenRightNow, WarehouseHours, FavWarehouseSelector },
  props: {
    warehouse: { type: Object, required: true },
    isDetail: { type: Boolean, default: false }
  }
};
</script>
