<template>
  <v-img :id="`static-image-${config.categoryBlockId}`" :src="srcImage" />
</template>
<script>
export default {
  name: "StaticImage",
  props: {
    config: { type: Object, required: true }
  },
  computed: {
    srcImage() {
      return this.$ebsn.meta(
        this.config,
        "categoryblocktype_StaticImage.IMAGE.location"
      );
    }
  }
};
</script>
