var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.handleView),expression:"handleView",modifiers:{"once":true}}],staticClass:"proposal-card-vertical-responsive",style:(_vm.getStyle('BACKGROUND_COLOR', 'TEXT_COLOR')),attrs:{"rounded":"lg","href":_vm.link},on:{"click":function($event){$event.preventDefault();return _vm.handleLink.apply(null, arguments)}}},[_c('v-row',{staticClass:"proposal-card-vertical-responsive-row",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"img-column",style:(_vm.getStyle('BG_ICON_COLOR')),attrs:{"cols":"12","sm":"6","lg":_vm.smallCard ? '5' : '8',"xl":_vm.smallCard ? '4' : '8',"order-sm":_vm.isCardInverted ? 1 : 2,"order":"1"}},[_c('v-img',{staticClass:"image-card",attrs:{"src":_vm.imgSrc,"alt":_vm.proposal.imgAlt,"title":_vm.proposal.imgDescription,"contain":_vm.$ebsn.meta(
            _vm.proposal,
            'metaData.category_proposal_type.IMAGE_CONTAIN',
            false
          )}})],1),_c('v-col',{staticClass:"d-flex flex-column content-column",attrs:{"cols":"12","sm":"6","lg":_vm.smallCard ? '7' : '4',"xl":_vm.smallCard ? '8' : '4',"order-sm":_vm.isCardInverted ? 2 : 1,"order":"2"}},[(_vm.proposal.descr)?_c('v-card-title',{domProps:{"innerHTML":_vm._s(_vm.proposal.descr)}}):_vm._e(),(_vm.proposal.content)?_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.proposal.content)}}):_vm._e(),_c('v-spacer'),(
          _vm.link &&
            _vm.$ebsn.meta(_vm.proposal, 'category_proposal_type.BTN_TEXT', false)
        )?_c('v-card-actions',{class:_vm.$ebsn.meta(
            _vm.proposal,
            'category_proposal_type.BTN_POSITION',
            ' justify-start justify-sm-end '
          )},[_c('v-btn',{style:(_vm.$ebsn.meta(_vm.proposal, 'category_proposal_type.BTN_OUTLINED', false)
              ? _vm.getStyle('BACKGROUND_COLOR', 'TEXT_COLOR')
              : _vm.getStyle('TEXT_COLOR', 'BACKGROUND_COLOR')),attrs:{"href":_vm.link,"depressed":"","large":"","color":"primary","outlined":_vm.$ebsn.meta(_vm.proposal, 'category_proposal_type.BTN_OUTLINED', false)},on:{"click":function($event){$event.preventDefault();return _vm.handleLink.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$ebsn.meta( _vm.proposal, "category_proposal_type.BTN_TEXT", "Visualizza" ))+" ")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }