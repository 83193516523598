var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAuthenticated && _vm.favWarehouse)?_c('div',{staticClass:"favourite-wh-row green lighten-2",style:(_vm.style),attrs:{"id":`favourite-wh-${_vm.config.categoryBlockId}`}},[_c('ebsn-style',{attrs:{"target":_vm.config,"path":"metaData.categoryblocktype_FavouriteWh.CSS_STYLE","styleWrapper":`#favourite-wh-${_vm.config.categoryBlockId}`}}),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('ebsn-meta',{style:(`color: ${_vm.$ebsn.meta(
          this.config,
          'categoryblocktype_FavouriteWh.TITLE_COLOR',
          'currentColor'
        )}; `),attrs:{"target":_vm.config,"path":"categoryblocktype_FavouriteWh.TITLE","tag":"h2"}}),_c('ebsn-meta',{attrs:{"target":_vm.config,"path":"categoryblocktype_FavouriteWh.DESCRIPTION","tag":"div"}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"5","lg":"4"}},[(_vm.favWarehouse == -1)?_c('v-card',{staticClass:"no-fav-wh-card primary pa-4 h-100",attrs:{"flat":"","tile":""}},[_c('v-card-title',{staticClass:"no-fav-wh-title text-h3 default--text"},[_vm._v(" "+_vm._s(_vm.$t("favouriteWhBlock.noFavWhTitle"))+" ")]),_c('v-card-text',{staticClass:"no-fav-wh-descr default--text"},[_vm._v(" "+_vm._s(_vm.$t("favouriteWhBlock.noFavWhDescr"))+" ")]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"min-width":"200","depressed":"","outlined":"","to":{
                name: 'ProfileUpdate',
                query: {
                  navToRefName: 'favouriteWh'
                }
              }}},[_vm._v(" "+_vm._s(_vm.$t("favouriteWhBlock.noFavWhBtn"))+" ")])],1)],1):_vm._e(),_c('WarehouseInfo',{key:_vm.favWarehouse.warehouseId,attrs:{"warehouse":_vm.favWarehouse}})],1),_c('v-col',{attrs:{"cols":"12","md":"7","lg":"8"}},[_c('div',{staticClass:"warehouse-leaflet-slider pa-4"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("favouriteWhBlock.flyersTitle"))+" ")]),_c('leaflet-slider',{attrs:{"parentWarehouseId":_vm.favWarehouse.warehouseId}})],1)])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }