const UPDATE_SITE = "updateSite";
const SET_APPLICATION_CONFIG = "setApplicationConfig";

const state = {
  site: "ecommerce",
  applicationConfig: {}
};
const getters = {
  getSite: state => {
    return state.site;
  },
  applicationConfig: state => {
    return state.applicationConfig;
  }
};

const actions = {
  [UPDATE_SITE]({ commit }, value) {
    commit(UPDATE_SITE, value);
  },
  [SET_APPLICATION_CONFIG]({ commit }, value) {
    commit(SET_APPLICATION_CONFIG, value);
  }
};
const mutations = {
  [UPDATE_SITE]: (state, value) => {
    state.site = value;
  },
  [SET_APPLICATION_CONFIG]: (state, value) => {
    state.applicationConfig = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
