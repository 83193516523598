var render = function render(_c,_vm){return _c('div',{staticClass:"qty-wrap rounded-sm",class:{
    'not-empty': _vm.parent.quantity > 0,
    'show-add-btn': _vm.parent.quantity < 1
  },on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('v-btn',{staticClass:"minus quantity-btn",attrs:{"aria-label":"Diminuisci quantità","depressed":"","color":"white","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.parent.minus(
        true,
        { infos: { ..._vm.props.selectedOptions } },
        {
          searchUid: _vm.props.product.searchUid,
          position: _vm.props.position
        }
      )}}},[_c('v-icon',[_vm._v("$minus")])],1),_c('div',{staticClass:"val-cnt"},[_c('span',{staticClass:"val"},[_vm._v(_vm._s(_vm.parent.quantity)+_vm._s(_vm.parent.unit))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.parent.quantityPerUnit))])]),_c('v-btn',{staticClass:"plus quantity-btn",attrs:{"aria-label":"Aumenta quantità","color":"white","small":"","depressed":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.parent.plus(
        true,
        {
          infos: {
            ..._vm.props.selectedOptions
          }
        },
        {
          searchUid: _vm.props.product.searchUid,
          position: _vm.props.position
        }
      )}}},[_c('v-icon',[_vm._v("$plus")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }