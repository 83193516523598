var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.giftCertificate?.categoryProposal?.metaData?.category_proposal_type
      ?.MODE === 'badge'
  )?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"product-gift-certificates d-flex flex-column justify-space-around",class:_vm.boxClass + ' ' + _vm.animationClass,style:({
    width: _vm.boxWidth + 'px',
    height: _vm.boxHeight + 'px',
    'animation-delay': Math.random() * 5 + 's'
  }),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[_c('div',{staticClass:"promo-tag badge-container font-weight-bold"},[(_vm.badgeLabel)?_c('div',{staticClass:"badge-tag"},[_vm._v(" "+_vm._s(_vm.badgeLabel)+" ")]):_vm._e(),(_vm.icon)?_c('v-icon',{attrs:{"alt":_vm.badgeLabel}},[_vm._v(" $"+_vm._s(_vm.icon.substring(0, _vm.icon.length - 4))+" ")]):(_vm.badgeValue)?_c('span',{staticClass:"d-block badge-value"},[_vm._v(" "+_vm._s(_vm.badgeValue)),_c('em',{staticClass:"small",domProps:{"innerHTML":_vm._s(_vm.badgeUnit)}})]):_vm._e(),_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[(_vm.isOpen)?_c('div',{staticClass:"more-content"},[_c('span',{staticClass:"promo-body"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.description)}}),(_vm.giftCertificate.giftCode && _vm.isOpen)?_c('v-btn',{staticClass:"btn-add",attrs:{"elevation":"0"},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.$t("checkout.giftCodeUseNow"))+" ")]):_vm._e(),(_vm.link)?_c('v-btn',{staticClass:"btn-more",attrs:{"outlined":"","to":_vm.link},on:{"click":_vm.closeWindow}},[_vm._v(" "+_vm._s(_vm.btnLabel)+" ")]):_vm._e()],1):_vm._e()])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }