<template>
  <v-card
    v-if="isAuthenticated"
    outlined
    class="user-delivery-service-block navbar-address-selector-opener ma-3 pl-2 py-1 d-flex d-md-none align-center"
    :style="style"
  >
    <navbar-delivery-service :shippingAddress="shippingAddress" />
  </v-card>
</template>
<style lang="scss">
.user-delivery-service-block {
  .navbar-delivery-service {
    width: 100%;
  }
  .change-service-img {
    height: 56px;
    width: 56px;
    filter: invert(90%);
  }
  .open-address-selector-btn {
    margin-left: auto;
    .v-btn__content {
      width: 55px;
      font-size: 0.875rem;
      // font-size: 1rem;
      white-space: normal;
      text-align: right;
    }
  }
}
</style>
<script>
import NavbarDeliveryService from "@/components/navigation/NavbarDeliveryService.vue";

import categoryBlockType from "./categoryBlockType";
import cartInfo from "~/mixins/cartInfo";

import { mapGetters } from "vuex";

export default {
  name: "UserDeliveryService",
  props: {
    config: { type: Object, required: false },
    container: { type: Boolean, default: false }
  },
  mixins: [categoryBlockType, cartInfo],
  components: { NavbarDeliveryService },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  }
};
</script>
