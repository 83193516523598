var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"delivery-map"},[_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{
      lat: 44.3948,
      lng: 8.9454
    },"zoom":10,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUI: false,
      ..._vm.mapStyle
    }}},[_c('GmapCluster',{attrs:{"styles":_vm.clusterStyles}},_vm._l((_vm.addressList),function(address,i){return _c('GmapMarker',{key:address.addressId,ref:'warehousMarker_' + address.addressId,refInFor:true,attrs:{"position":_vm.google &&
            new _vm.google.maps.LatLng(address.latitude, address.longitude),"clickable":true,"icon":"/pdv/pin.png"},on:{"click":function($event){return _vm.toggleInfoWindow(address, i)}}})}),1),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){$event.stopPropagation();_vm.infoWinOpen = false}}},[_vm._v(" "+_vm._s(_vm.selectedAddress)+" Contenuto ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }