var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"LeafletSlider-wh-row",style:(_vm.style),attrs:{"id":`LeafletSlider-wh-${_vm.config.categoryBlockId}`}},[_c('ebsn-style',{attrs:{"target":_vm.config,"path":"metaData.categoryblocktype_LeafletSlider.CSS_STYLE","styleWrapper":`#LeafletSlider-wh-${_vm.config.categoryBlockId}`}}),_c('v-container',{attrs:{"fluid":""}},[_c('ebsn-meta',{style:(`color: ${_vm.$ebsn.meta(
          this.config,
          'categoryblocktype_LeafletSlider.TITLE_COLOR',
          'currentColor'
        )}; `),attrs:{"target":_vm.config,"path":"categoryblocktype_LeafletSlider.TITLE","tag":"h2"}}),_c('ebsn-meta',{attrs:{"target":_vm.config,"path":"categoryblocktype_LeafletSlider.DESCRIPTION","tag":"div"}}),_c('div',{staticClass:"py-12",staticStyle:{"position":"relative"}},[_c('swiper',{attrs:{"options":_vm.swiperOption}},_vm._l((_vm.leaflets),function(leaflet){return _c('swiper-slide',{key:leaflet.leafletId},[_c('LeafletCard',{attrs:{"leaflet":leaflet}})],1)}),1),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_LeafletSlider.SHOW_BULLETS',
            true
          ) && _vm.leaflets.length > 1
        )?_c('div',{staticClass:"swiper-pagination",attrs:{"id":`leaflet-pagination-${_vm.config.categoryBlockId}`}}):_vm._e(),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_LeafletSlider.SHOW_ARROWS',
            false
          ) && _vm.leaflets.length > 1
        )?_c('div',{staticClass:"swiper-button-prev",attrs:{"id":`leaflet-slider-prev-${_vm.config.categoryBlockId}`}}):_vm._e(),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_LeafletSlider.SHOW_ARROWS',
            false
          ) && _vm.leaflets.length > 1
        )?_c('div',{staticClass:"swiper-button-next",attrs:{"id":`leaflet-slider-next-${_vm.config.categoryBlockId}`}}):_vm._e()],1),_c('div',{staticClass:"align-right"},[_c('a',{attrs:{"to":{ name: 'Leaflets' }}},[_vm._v(_vm._s(_vm.$t("leafletSlider.goToAllLeaflet")))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }