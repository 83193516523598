var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.config.proposals && _vm.config.proposals.length > 0)?_c('div',{staticClass:"banner-slider",style:(_vm.style),attrs:{"id":`banner-slider-${_vm.config.categoryBlockId}`},on:{"click":_vm.clicked}},[_c('ebsn-style',{attrs:{"target":_vm.config,"path":"metaData.categoryblocktype_BannerSlider.CSS_STYLE","styleWrapper":`#banner-slider-${_vm.config.categoryBlockId}`}}),_c('div',{class:{ 'container container--fluid': _vm.container }},[_c('ebsn-meta',{style:(`color: ${_vm.$ebsn.meta(
          this.config,
          'categoryblocktype_BannerSlider.TITLE_COLOR',
          'currentColor'
        )}; `),attrs:{"target":_vm.config,"path":"categoryblocktype_BannerSlider.TITLE","tag":"h2"}}),_c('ebsn-meta',{attrs:{"target":_vm.config,"path":"categoryblocktype_BannerSlider.DESCRIPTION","tag":"div"}}),_c('div',{staticClass:"slider banner-slider"},[_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.config.proposals),function(proposal,index){return _c('swiper-slide',{key:proposal.id},[_c(_vm.getProposalModelName(proposal),{tag:"component",class:`slide-num-${index}`,attrs:{"proposal":proposal,"position":_vm.config.templateBlock.codInt}})],1)}),1),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_BannerSlider.SHOW_BULLETS',
            true
          ) && _vm.config.proposals.length > 1
        )?_c('div',{staticClass:"swiper-pagination",attrs:{"id":`banner-pagination-${_vm.config.categoryBlockId}`}}):_vm._e(),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_BannerSlider.SHOW_ARROWS',
            false
          )
        )?_c('div',{staticClass:"swiper-button-prev",attrs:{"id":`banner-slider-prev-${_vm.config.categoryBlockId}`}}):_vm._e(),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_BannerSlider.SHOW_ARROWS',
            false
          )
        )?_c('div',{staticClass:"swiper-button-next",attrs:{"id":`banner-slider-next-${_vm.config.categoryBlockId}`}}):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }