<template>
  <div
    v-if="config.proposals && config.proposals.length > 0"
    @click="clicked"
    :id="`banner-slider-${config.categoryBlockId}`"
    class="banner-slider"
    :style="style"
  >
    <ebsn-style
      :target="config"
      path="metaData.categoryblocktype_BannerSlider.CSS_STYLE"
      :styleWrapper="`#banner-slider-${config.categoryBlockId}`"
    />
    <div :class="{ 'container container--fluid': container }">
      <ebsn-meta
        :target="config"
        path="categoryblocktype_BannerSlider.TITLE"
        tag="h2"
        :style="
          `color: ${$ebsn.meta(
            this.config,
            'categoryblocktype_BannerSlider.TITLE_COLOR',
            'currentColor'
          )}; `
        "
      />
      <ebsn-meta
        :target="config"
        path="categoryblocktype_BannerSlider.DESCRIPTION"
        tag="div"
      />

      <div class="slider banner-slider">
        <swiper :options="swiperOption" ref="swiperRef">
          <swiper-slide
            v-for="(proposal, index) in config.proposals"
            :key="proposal.id"
          >
            <component
              :class="`slide-num-${index}`"
              :is="getProposalModelName(proposal)"
              :proposal="proposal"
              :position="config.templateBlock.codInt"
            />
          </swiper-slide>
        </swiper>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_BannerSlider.SHOW_BULLETS',
              true
            ) && config.proposals.length > 1
          "
          :id="`banner-pagination-${config.categoryBlockId}`"
          class="swiper-pagination"
        ></div>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_BannerSlider.SHOW_ARROWS',
              false
            )
          "
          :id="`banner-slider-prev-${config.categoryBlockId}`"
          class="swiper-button-prev"
        ></div>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_BannerSlider.SHOW_ARROWS',
              false
            )
          "
          :id="`banner-slider-next-${config.categoryBlockId}`"
          class="swiper-button-next"
        ></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.banner-slider {
  .swiper-button-disabled {
    display: none;
  }
  .swiper-slide {
    height: auto;
    .proposal-card.v-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      .v-img {
        max-height: 180px;
      }
    }
  }
}
</style>
<script>
import ProposalImage from "./elements/ProposalImage.vue";
import ProposalCardHorizontalResponsive from "./elements/ProposalCardHorizontalResponsive.vue";
import ProposalCardOverlay from "./elements/ProposalCardOverlay.vue";
import ProposalCardVerticalResponsive from "./elements/ProposalCardVerticalResponsive.vue";
import ProposalButton from "./elements/ProposalButton.vue";
import clickHandler from "~/mixins/clickHandler";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

export default {
  name: "BannerSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: false }
  },
  components: {
    ProposalImage,
    ProposalCardHorizontalResponsive,
    ProposalCardOverlay,
    ProposalCardVerticalResponsive,
    ProposalButton
  },
  mixins: [deliveryReactive, categoryBlockType, clickHandler],
  data() {
    return {
      swiperRef: null,
      swiperDefaultOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        loop: false,
        // breakpoints: {
        //   0: {
        //     slidesPerView: 1
        //   },
        //   500: {
        //     slidesPerView: "auto"
        //   }
        // },
        pagination: {
          el: `#banner-pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#banner-slider-prev-${this.config.categoryBlockId}`,
          nextEl: `#banner-slider-next-${this.config.categoryBlockId}`
        }
      }
    };
  },
  methods: {
    reload() {
      if (this.$refs.swiperRef.swiperInstance) {
        this.$refs.swiperRef.swiperInstance.slideTo(0);
      }
    }
  }
};
</script>
