<template>
  <v-card
    class="service-discovery-container d-flex text-center flex-column justify-center align-center h-inherit px-3 rounded white--text"
  >
    <v-img
      class="mt-3 px-5"
      max-width="300"
      max-height="100"
      contain
      src="/img_layout/icon-services.png"
      alt="Icone servizi"
    ></v-img>
    <v-card-title class="justify-center text-h4">
      {{ $t("addresses.autoDiscovery.title") }}
    </v-card-title>
    <v-card-subtitle class="white--text">
      {{ $t("addresses.autoDiscovery.description") }}
    </v-card-subtitle>
    <v-card-text class="d-flex mt-2 pa-2 mt-lg-6 flex-column">
      <vue-google-autocomplete
        id="map"
        classname="autodiscovery-widget"
        ref="address"
        :placeholder="$t(placeholder)"
        :enable-geolocation="true"
        v-on:placechanged="getAddressData"
        country="it"
        v-if="googleEnabled"
      >
      </vue-google-autocomplete>
    </v-card-text>
    <v-card-actions class="w-100">
      <v-btn
        depressed
        block
        color="primary"
        @click="goToServiceAutodiscovery"
        >{{ $t("widget.serviceDiscovery.verify") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<style scoped lang="scss">
.service-discovery-container {
  background-color: #95c21e;
  .autodiscovery-widget {
    background-color: white;
    line-height: 20px;
    border-radius: $border-radius-root * 2;
    padding: 10px;
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      h2 {
        line-height: 30px !important;
      }
    }
  }
}
</style>
<script>
import { mapActions } from "vuex";
// import AddressService from "~/service/addressService";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import gmapsInit from "../buttons/gmaps";
import includes from "lodash/includes";

var google;

export default {
  name: "AutodiscoveryWidget",
  components: { VueGoogleAutocomplete },
  props: {
    placeholder: {
      type: String,
      default: "addresses.autoDiscovery.placeholder"
    }
  },
  data() {
    return {
      warehouses: [],
      lockers: [],
      dataValidated: {},
      deliveryServiceAvailable: null,
      results: false,
      googleEnabled: false,
      addressData: null,
      placeResult: null
    };
  },
  methods: {
    ...mapActions({ setAddress: "cart/setAddress" }),
    async getAddressData(addressData, placeResult) {
      try {
        placeResult.address_components.forEach(element => {
          if (element.types[0] == "country") {
            addressData.country = { iso: element.short_name };
            return;
          }
        });

        // this.dataValidated = await AddressService.isValidAddress({
        //   country: addressData.country,
        //   city: addressData.locality
        //     ? addressData.locality
        //     : addressData.administrative_area_level_3,
        //   postalcode: addressData.postal_code,
        //   address1: addressData.route,
        //   addressNumber: addressData.street_number,
        //   gpsLongitude: addressData.longitude,
        //   gpsLatitude: addressData.latitude,
        //   longitude: addressData.longitude,
        //   latitude: addressData.latitude,
        //   province: this.getProvince(placeResult)
        // });

        this.addressData = addressData;
        this.placeResult = placeResult;
        this.deliveryServiceAvailable = true;
      } catch (err) {
        console.log(err);
        this.deliveryServiceAvailable = false;
      }
      //lookup drive
      // try {
      //   this.warehouses = await AddressService.getAddressByDeliveryService(
      //     1,
      //     addressData.latitude,
      //     addressData.longitude,
      //     global.config.proximityDefaultDistance
      //   );
      // } catch (err) {
      //   console.log(err);
      // }
      // try {
      //   //lookup locker
      //   this.lockers = await AddressService.getAddressByDeliveryService(
      //     2,
      //     addressData.latitude,
      //     addressData.longitude,
      //     global.config.proximityDefaultDistance
      //   );
      // } catch (err) {
      //   console.log(err);
      // }
      this.results = true;
    },

    getProvince(placeResult) {
      let area = placeResult.address_components.find(p => {
        return includes(p.types, "administrative_area_level_2");
      });
      return area["short_name"];
    },
    goToServiceAutodiscovery() {
      if (this.results) {
        this.$router.push({
          name: "ServiceAutodiscovery",
          params: {
            additionalData: {
              addressString: this.$refs.address.$el.value,
              addressData: this.addressData,
              placeResult: this.placeResult
            }
          }
        });
      }
    }
  },
  async mounted() {
    try {
      google = await gmapsInit(global.config.google_api_key);
      if (google) {
        this.googleEnabled = true;
      }
    } catch (error) {
      throw new Error("Error Loading GMAPS");
    }
  }
};
</script>
