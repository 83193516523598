<template>
  <div
    :id="`product-${product.id}`"
    @click="$emit('selectProduct')"
    class="product-card-wrapper white pa-3 px-md-2 rounded-md"
  >
    <v-card
      elevation="0"
      class="product-card"
      :to="{ name: 'Product', params: { slug: product.slug } }"
      ref="productCard"
      :key="componentKey"
      @mouseover.once="getRecipeList"
    >
      <div class="product">
        <div class="left-container">
          <ProductVendor
            v-if="product.vendor && product.vendor.url"
            :vendor="product.vendor"
          />
          <ProductAcqLimit :product="product" />
          <div class="product-badge novita" v-if="product.newProduct">
            Novità
          </div>
        </div>
        <div class="right-container">
          <div
            class="product-badge"
            v-if="product.productInfos.BADGE"
            :class="product.productInfos.BADGE"
          >
            {{ product.productInfos.BADGE }}
          </div>
          <ProductPromo
            v-if="product.warehousePromo"
            :warehousePromo="product.warehousePromo"
          />
          <template
            v-else-if="
              product.userGiftCertificates &&
                product.userGiftCertificates.length > 0
            "
          >
            <product-gift-certificate
              v-for="userGiftCertificate in product.userGiftCertificates"
              :key="userGiftCertificate.giftCertificate.giftCertificateId"
              :giftCertificate="userGiftCertificate.giftCertificate"
              :containerRef="$refs.productCard"
              @add="giftAdded"
              :wrapperId="`promo-wrapper-${product.productId}`"
            />
          </template>
        </div>
        <div class="d-flex flex-column w-100 product">
          <img
            :src="product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img align-self-center mt-4"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />
          <!-- div with name and info -->
          <div
            class="description default--text d-flex justify-space-between align-start w-100 mt-3 px-2"
          >
            <span class="name">
              {{ product.name }}
            </span>
            <v-tooltip
              v-if="
                (productClasses && productClasses.length > 0) ||
                  product.description ||
                  product.shortDescr
              "
              content-class="product-class-tooltip"
              left
              nudge-top="20"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mt-1"
                  @click.stop.prevent
                  @mousedown.stop
                  v-bind="attrs"
                  v-on="on"
                  color="grey darken-1"
                >
                  $info
                </v-icon>
              </template>
              <span
                class="d-block product-descr mb-4"
                v-if="product.description || product.shortDescr"
              >
                {{ product.shortDescr }}&nbsp;{{ product.description }}
              </span>
              <div class="d-flex product-class-container" v-if="productClasses">
                <template v-if="productClasses && productClasses.length > 0">
                  <ProductClass
                    v-for="pClass in productClasses"
                    :pClass="pClass"
                    :key="pClass.productClassid"
                  />
                </template>
              </div>
            </v-tooltip>
          </div>
          <div v-if="product.available > 0" class="actions">
            <ProductPrice v-if="product.priceDisplay" :product="product" />
            <div class="user-action-preferences">
              <QtyWrap
                :key="key"
                :selectedOptions="selectedOptions"
                :product="product"
                :position="position"
              />
              <SelectedOptionsMenu
                class="mt-1"
                v-if="selectOptionsArray && selectOptionsArray.length > 0"
                :product="product"
                :selectOptionsArray="selectOptionsArray"
                @onOptionChanged="onOptionChanged"
                @setSelectOptions="setSelectOptions"
              />
            </div>
          </div>
          <div
            v-else
            class="actions justify-center text-center text-caption error--text font-weight-bold"
          >
            {{ $t("product.notAvailable") }}
          </div>
        </div>
      </div>
    </v-card>
    <div class="product-footer-actions">
      <v-tooltip top :key="heartKey">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click.stop.prevent="addToFavorites"
            @mousedown.stop
            aria-label="Aggiungi ad una lista"
          >
            <v-icon color="primary" v-if="highlight">$starFull</v-icon>
            <v-icon v-else color="grey darken-1">$star</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("product.addToList") }}</span>
      </v-tooltip>
      <v-tooltip top v-if="recipeList && recipeList.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            link
            :to="recipeList[0].recipe_url"
            aria-label="Vai alle ricette con questo prodotto"
          >
            <v-icon color="grey darken-1">$recipe</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("product.goToRecipe") }}</span>
      </v-tooltip>
      <div v-if="product.leadTime" class="lead-time-container">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-img
              contain
              class="lead-time-img"
              v-bind="attrs"
              v-on="on"
              eager
              src="/img_layout/product/lead-time.png"
              alt="lead time"
              title="lead time"
            >
              <div class="lead-time-overlay">
                {{
                  $t("product.leadTimeHours", {
                    hours: product.leadTime * 24
                  })
                }}
              </div></v-img
            >
          </template>
          <span>{{
            $t("product.leadTime", { hours: product.leadTime * 24 })
          }}</span>
        </v-tooltip>
      </div>
      <v-spacer />
      <v-btn
        v-if="product.available > 0"
        large
        width="136"
        class="add-to-cart-btn"
        outlined
        color="primary"
        @click.stop.prevent="
          addToCart(
            quantity,
            { infos: { ...selectedOptions } },
            {
              searchUid: product.searchUid,
              position: position
            }
          )
        "
      >
        <v-icon>$cart</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.swiper-slide .product-card-wrapper {
  padding: 0;
  border-radius: 4px;
}
.product-card-wrapper {
  padding: 10px;
  box-shadow: 0px 3px 20px #00000029;
}
.product-card {
  img.vendor-icon {
    width: 45px;
  }
  .right-container {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .left-container {
    position: absolute;
    top: 16px;
    left: 16px;
  }
  // height: $default-card-height;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: $default-card-width;
    max-width: 100%;
  }
  &.v-sheet.v-card:not(.v-sheet--outlined) {
    -webkit-box-shadow: 0px 3px 20px #00000029;
    box-shadow: 0px 3px 20px #00000029;
  }
  .product-img {
    width: 170px;
    height: 170px;
    // @media #{map-get($display-breakpoints, 'xs-only')} {
    //   margin: 0px 17px;
    //   width: 100px;
    //   height: 100px;
    // }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-price {
      width: 140px;
      text-align: center;
    }
  }

  .selectOptionsMenu {
    z-index: 12;
  }
}
</style>
<script>
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPrice from "./ProductPrice.vue";
import QtyWrap from "./QtyWrap.vue";
import ProductClass from "./ProductClass.vue";
import ProductVendor from "@/components/product/ProductVendor.vue";
// import ProductInfo from "./ProductInfo.vue";
// import LeadTime from "./ProductLeadTime.vue";
// import LockCutoff from "./ProductLockCutoff.vue";
// import AxBCutoff from "./ProductAxBCutoff.vue";
// import DayLock from "./ProductDayLock.vue";
import ProductGiftCertificate from "@/components/gift/ProductGiftCertificate.vue";
import SelectedOptionsMenu from "@/components/product/SelectedOptionsMenu.vue";
import ProductPromo from "@/components/product/ProductPromo.vue";

import productMixin from "~/mixins/product";

import WpBaskoCustomService from "@/service/wpBaskoCustomService";

import {
  // mapState,
  mapGetters
} from "vuex";

import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductCard",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined }
  },
  components: {
    // ProductInfo,
    ProductClass,
    ProductVendor,
    ProductAcqLimit,
    ProductPrice,
    ProductGiftCertificate,
    QtyWrap,
    SelectedOptionsMenu,
    ProductPromo
    // LeadTime,
    // LockCutoff,
    // AxBCutoff,
    // DayLock
  },
  mixins: [productMixin],
  data() {
    return {
      // isMouseDown: false,
      selectOptionsArray: [],
      selectedOptions: {},
      componentKey: 0,
      key: 0,
      recipeList: []
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    // ...mapState({
    //   cart: ({ cart }) => cart.cart
    // })
    item() {
      return null;
    }
  },
  methods: {
    /* onMouseDownEvent() {
      this.isMouseDown = true;
    },
    onMouseUpEvent() {
      if (this.isMouseDown) {
        this.addToCart(
          this.quantity,
          { infos: { ...this.selectedOptions } },
          {
            searchUid: this.product.searchUid,
            position: this.position
          }
        );
      }
      this.isMouseDown = false;
    },*/
    giftAdded() {
      this.plus();
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.key;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    async getRecipeList() {
      this.recipeList = await WpBaskoCustomService.getRecipeByProductId(
        this.product.productId
      );
    },
    plusClicked() {
      this.plus();
    },
    minusClicked() {
      this.minus();
    },
    handleClick() {
      this.$emit("selectProduct");
    }
  },
  mounted() {
    if (this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
  }
};
</script>
