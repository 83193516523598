import get from "lodash/get";
import GiftProductSelected from "./GiftProductSelector.vue";
import analyticsService from "@/commons/service/analyticsService";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    giftCertificate: { type: Object, required: false }
  },
  computed: {
    name() {
      return get(
        this.giftCertificate,
        "categoryProposal.name",
        this.giftCertificate.name
      );
    },
    badgeLabel() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.BADGE_LABEL"
      );
    },
    badgeValue() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.BADGE_VALUE"
      );
    },
    badgeUnit() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.BADGE_UNIT"
      );
    },
    description() {
      return get(
        this.giftCertificate,
        "categoryProposal.descr",
        this.giftCertificate.description
      );
    },
    content() {
      return get(this.giftCertificate, "categoryProposal.content");
    },
    cssClass() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.CSS_CLASS",
        "ccno"
      );
    },
    animationClass() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.BADGE_ANIMATION"
      );
    },
    hideEndDate() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.HIDE_END_DATE",
        false
      );
    },
    icon() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.BADGE_ICON"
      );
    },
    src() {
      return get(this.giftCertificate, "categoryProposal.img");
    },
    srcMobile() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.IMAGE_MOBILE",
        get(this.giftCertificate, "categoryProposal.img")
      );
    },
    btnLabel() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.BTN_LABEL",
        this.$t("profile.gifts.more")
      );
    },
    link() {
      if (get(this.giftCertificate, "categoryProposal.proposalUrl")) {
        return this.giftCertificate.categoryProposal.proposalUrl;
      } else if (get(this.giftCertificate, "categoryProposal.slug")) {
        return {
          name: "Category",
          params: {
            pathMatch: this.giftCertificate.categoryProposal.slug
          }
        };
      } else {
        return "";
      }
    },
    bgColor() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.BACKGROUND_COLOR"
      );
    },
    isAssignable() {
      return !!this.giftCertificate.giftCode;
    },
    userGiftCertificateId() {
      let userGiftCertificateId = null;

      if (this.giftCertificate.userGiftCertificates?.length > 0) {
        this.giftCertificate.userGiftCertificates.every(userGiftCertificate => {
          if (userGiftCertificate.cartId == this.cart.cartId) {
            userGiftCertificateId = userGiftCertificate.userGiftCertificateId;
            return false;
          }
        });
      }
      return userGiftCertificateId;
    },
    listUsedCertificates() {
      if (this.giftCertificate.userGiftCertificates?.length > 0) {
        return this.giftCertificate.userGiftCertificates.filter(
          userGiftCertificate => {
            return userGiftCertificate.orderId;
          }
        );
      } else {
        return [];
      }
    },
    listAvailableCertificates() {
      if (this.giftCertificate.userGiftCertificates?.length > 0) {
        return this.giftCertificate.userGiftCertificates.filter(
          userGiftCertificate => {
            return !userGiftCertificate.orderId;
          }
        );
      } else {
        return [];
      }
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      addGiftCode: "cart/addGiftCode",
      sendGiftCode: "cart/sendGiftCode",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async addUserGift(userGiftCertificateId) {
      if (
        this.giftCertificate.giftAdjustmentType.giftAdjustmentTypeId == 1001
      ) {
        //open modal
        this.dialogInstance = await this.$dialog.show(GiftProductSelected, {
          waitForResult: true,
          width: 700,
          giftCertificate: this.giftCertificate
        });
        const result = await this.dialogInstance.wait();
        if (result) {
          await this.sendGiftCode(
            this.giftCertificate.giftCode,
            result.item.itemId
          );
        }
      } else {
        await this.addGiftCode(userGiftCertificateId);
      }
      this.$emit("add");
    },
    async add() {
      if (this.giftCertificate.userGiftCertificates?.length > 0) {
        let userGiftCertificate = this.giftCertificate.userGiftCertificates[0];
        await this.addGiftCode(userGiftCertificate.userGiftCertificateId);
      } else {
        await this.sendGiftCode(this.giftCertificate.giftCode);
      }
      console.log("BUONO - giftCertificate.js", this.giftCertificate);
      analyticsService.trackEvent(
        "addGiftCertificate",
        "giftCertificate",
        this.giftCertificate?.description || "Buono"
      );
      // await this.sendGiftCode(this.giftCertificate.giftCode);

      this.$emit("add");
    },
    async remove(userGiftCertificateId) {
      if (userGiftCertificateId) {
        await this.removeGiftCode(userGiftCertificateId);
      } else {
        await this.removeGiftCode(this.userGiftCertificateId);
      }
      console.log("BUONO - giftCertificate.js", this.giftCertificate);
      analyticsService.trackEvent(
        "removeGiftCertificate",
        "giftCertificate",
        this.giftCertificate?.description || "Buono"
      );

      this.$emit("remove");
    }
  }
};
