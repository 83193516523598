import axios from "~/plugins/axios";
export default {
  notificationHistoryList(message_type_id, page, page_size) {
    return axios
      .get("/ebsn/api/notification/history", {
        params: {
          message_type_id: message_type_id,
          page: page,
          page_size: page_size
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  updatePreferContact(contactPreferPersonInfos) {
    let url = "/ebsn/api/basko-registration/update_contact_preference";
    return axios
      .post(url, contactPreferPersonInfos)
      .then(response => response.data.data.user)
      .catch(err => err);
  },
  provinceList(countryId, homeDeliveryOnly = false) {
    return axios
      .get("/ebsn/api/geo/province-list", {
        params: {
          country_id: countryId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  cityList(provinceId, homeDeliveryOnly = false) {
    return axios
      .get("/ebsn/api/geo/city-list", {
        params: {
          province_id: provinceId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  postcodeList(cityId, homeDeliveryOnly = false) {
    return axios
      .get("/ebsn/api/geo/postcode-list", {
        params: {
          city_id: cityId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  streetList(cityId, postcodeId, homeDeliveryOnly = false) {
    return axios
      .get("/ebsn/api/geo/street-list", {
        params: {
          city_id: cityId,
          postcode_id: postcodeId,
          home_delivery_only: homeDeliveryOnly
        }
      })
      .then(data => {
        return data.data.data.results;
      });
  },
  registrationPrimaUser(user, primaCardAction) {
    let params = {
      prima_card_action: primaCardAction
    };

    let url = "/ebsn/api/prima-card/register-user";
    return axios
      .post(url, user, { params })
      .then(response => {
        return response.data;
      })
      .catch(err => err);

    // prima_card_action: primaAction
  },
  getAwardsOrders() {
    return axios.get("/ebsn/api/awards/view_orders").then(data => {
      return data.data;
    });
  },
  getAwardsStory() {
    return axios.get("/ebsn/api/awards/view_story").then(data => {
      return data.data;
    });
  },
  /* RIDER */
  getRiderListOrders(typeId) {
    return axios
      .get("/ebsn/api/rider/list-orders", {
        params: {
          type_id: typeId
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  getBenefits() {
    return axios
      .get(`/ebsn/api/cart-giftcertificate/cart-threshold`)
      .then(data => {
        if (data) {
          return data.data.data;
        } else {
          return null;
        }
      });
  },
  deliveredOrder(orderId) {
    let params = {
      order_id: orderId
    };

    let url = "/ebsn/api/rider/delivered-order";
    return axios
      .post(url, undefined, { params })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  assignOrder(orderId) {
    let params = {
      order_id: orderId
    };

    let url = "/ebsn/api/rider/assign-order";
    return axios
      .post(url, undefined, { params })
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  getOrder(orderId) {
    return axios
      .get("/ebsn/api/rider/view-order", {
        params: {
          order_id: orderId
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  setFavWarehouse(warehouseId) {
    let url = `/ebsn/api/basko-registration/update_default_store_address?storeAddressId=${warehouseId}`;
    return axios
      .post(url)
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  }
  /* RIDER */
};
