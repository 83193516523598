<template>
  <v-card
    class="gift-code-widget-card grey lighten-2"
    :to="link"
    height="100%"
    outlined
    @click.prevent="handleClick"
    :style="`background-color: ${bgColor}`"
  >
    <v-row class="justify-center align-center">
      <v-col cols="4">
        <v-img
          contain
          min-height="100px"
          :src="src"
          :alt="name"
          class="widget gift-code-widget-img"
        >
        </v-img>
      </v-col>
      <v-col cols="8">
        <div style="flex:0 0 50%">
          <div
            class="certificate-card-content d-flex flex-column justify-space-around align-center"
            outlined
          >
            <v-card-title class="pt-2 pb-0">
              {{ name }}
            </v-card-title>
            <v-spacer />
            <v-card-text class="text-center py-1">
              <div v-html="description"></div>
            </v-card-text>
            <v-card-text class="text-center py-1">
              <div v-html="content"></div>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-btn
                v-if="isAssignable && userGiftCertificateId != null"
                outlined
                color="primary"
                block
                @click.stop.prevent="remove(userGiftCertificateId)"
                >{{ $t("giftCertificates.widget.giftCodeRemove") }}
              </v-btn>
              <v-btn
                v-else-if="isAssignable"
                depressed
                color="primary"
                block
                @click.stop.prevent="add"
                >{{ $t("giftCertificates.widget.giftCodeUseNow") }}</v-btn
              >
            </v-card-actions>
          </div>
        </div>
      </v-col>
    </v-row>

    <span v-if="hideEndDate || !giftCertificate.endDate" class="floating-text">
      <strong>{{ $t("giftCertificates.widget.overlayText") }}</strong>
    </span>
    <span v-else class="floating-text">
      <strong>{{ $t("checkout.expires") }}:</strong>
      {{ $dayjs(giftCertificate.endDate).format("DD MMM YYYY") }}
    </span>
  </v-card>
</template>
<style lang="scss">
.certificate-card-content {
  .v-card__text {
    p {
      margin-bottom: 0px;
    }
  }
  @media #{map-get($display-breakpoints, 'xl-only')} {
    .v-card__text {
      padding-top: 16px !important;
      padding-bottom: 16px !important;
    }
    .v-card__actions {
      padding-top: 8px !important;
    }
  }
}
@mixin color-modifiers {
  @each $name, $light, $base in $giftCertColors {
    &.#{$name} {
      color: #{$light};
      background-color: #{$base} !important;
    }
  }
}
.v-application .theme--light.v-card.gift-code-widget-card {
  @include color-modifiers;
  .floating-text {
    position: absolute;
    padding: 1px 8px;
    font-size: 0.8rem;
    color: var(--v-default-base);
    background: var(--v-white-base);
    opacity: 0.9;
    top: 10px;
    left: 10px;
    border-radius: $border-radius-root;
  }
  .widget {
    // .v-responsive__content {
    //   display: flex;
    //   align-items: flex-end;
    //   justify-content: center;
    // }
    .v-btn {
      margin-bottom: 20px;
      background-color: #fff;
    }
  }
}
</style>
<script>
import giftCertificate from "@/components/gift/giftCertificate";

export default {
  name: "GiftCodeWidgetCard",
  mixins: [giftCertificate]
};
</script>
