<template>
  <div
    v-if="isAuthenticated"
    :id="`prima-card-row-${config.categoryBlockId}`"
    :style="style"
    class="prima-card-row"
  >
    <ebsn-style
      :target="config"
      path="metaData.categoryblocktype_PrimaCardRow.CSS_STYLE"
      :styleWrapper="`#prima-card-row-${config.categoryBlockId}`"
    />
    <div :class="{ 'container container--fluid': container }">
      <ebsn-meta
        :target="config"
        path="categoryblocktype_PrimaCardRow.TITLE"
        tag="h2"
        :style="
          `color: ${$ebsn.meta(
            this.config,
            'categoryblocktype_PrimaCardRow.TITLE_COLOR',
            'currentColor'
          )}; `
        "
      />
      <ebsn-meta
        :target="config"
        path="categoryblocktype_PrimaCardRow.DESCRIPTION"
        tag="div"
      />

      <v-row class="data-row">
        <v-col cols="12" md="4" class="card-col">
          <span class="text-h6" v-if="fidelityCard">{{
            $t("primaCardRow.header.cardLogged")
          }}</span>
          <span class="text-h6" v-else>{{
            $t("primaCardRow.header.cardSlogged")
          }}</span>

          <transition name="flip" v-if="fidelityCard">
            <v-card
              @click="toggleCard()"
              v-bind:key="flipped"
              class="green lighten-2 d-flex flex-column rounded card-big"
            >
              <img
                class="card-img align-self-end mr-5 mt-4"
                src="/img_layout/prima_cards/prima-cards.svg"
                alt="Prima Card"
                title="Logo Prima Card"
              />
              <v-card-text v-if="!flipped" class="primary--text text--darken-1">
                {{ $t("primaCardRow.header.cardLogged") }} <br />
                <span class="d-block card-info text-h3"
                  >{{ cart.user.firstName }} {{ cart.user.lastName }}</span
                >
                {{ $t("primaCardRow.text.cardNumber") }}<br />
                <span class="d-block card-info text-h3">
                  {{ fidelityCard }}
                </span>
              </v-card-text>
              <barcode
                v-else
                :value="fidelityCard"
                :options="{ format: 'CODE128', width: 380, height: 150 }"
                class="align-self-center"
              />
            </v-card>
          </transition>
          <v-card
            v-else
            class="green lighten-2 d-flex flex-column rounded card-big"
          >
            <v-card-text
              v-if="!flipped"
              class="primary--text text--darken-1 mt-5"
            >
              {{ $t("primaCardRow.text.username") }} <br />
              <span class="d-block card-info text-h3"
                >{{ cart.user.firstName }}<br />
                {{ cart.user.lastName }}</span
              >
            </v-card-text>
            <v-spacer />
            <v-card-actions
              class="justify-end"
              v-if="!hasFidelityCard && !hasRequestedFidelityCard"
            >
              <v-btn
                color="primary"
                depressed
                min-width="200"
                :to="{
                  name: 'ProfileUpdate',
                  query: { navToRefName: 'wantPrima' }
                }"
              >
                {{ $t("primaCardRow.text.getPrimaCard") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="2" class="points-col">
          <span class="text-h6">{{ $t("primaCardRow.header.balance") }}</span>
          <v-card color="primary" class="card-small top">
            <v-card-title class="pb-1">{{
              $t("primaCardRow.text.points")
            }}</v-card-title>
            <v-card-text
              class="white--text text-h2 d-flex justify-space-between aling-end"
            >
              <i18n-n
                v-if="money >= 0"
                :value="money"
                format="currency"
                tag="span"
                class="formatted-price"
              >
                <template v-slot:fraction="slotProps"
                  ><span class="purse-small">{{
                    slotProps.fraction
                  }}</span></template
                >
                <template v-slot:currency="slotProps">
                  <span class="purse-small">{{ slotProps.currency }}</span>
                </template>
              </i18n-n>
              <span v-else>-</span>
              <img
                class="borsellino-img"
                title="Borsellino"
                src="/img_layout/prima_cards/borsellino.svg"
                alt="borsellino"
              />
            </v-card-text>
          </v-card>
          <v-card outlined color="primary" class="card-small">
            <v-card-title class="pb-1">{{
              $t("primaCardRow.text.purse")
            }}</v-card-title>
            <v-card-text
              class="text-h2 line-height-1 d-flex justify-space-between aling-end"
            >
              <i18n-n
                v-if="moneyCheckout >= 0"
                :value="moneyCheckout"
                format="currency"
                tag="span"
                class="formatted-price"
              >
                <template v-slot:fraction="slotProps"
                  ><span class="purse-small">{{
                    slotProps.fraction
                  }}</span></template
                >
                <template v-slot:currency="slotProps">
                  <span class="purse-small">{{ slotProps.currency }}</span>
                </template>
              </i18n-n>
              <span v-else>-</span>
              <img
                class="borsellino-img"
                title="Borsellino"
                src="/img_layout/prima_cards/borsellino.svg"
                alt="borsellino"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="buttons-col"
          v-if="buttonsConfig && buttonsConfig.length > 0"
        >
          <span class="text-h6">{{ $t("primaCardRow.header.service") }}</span>
          <v-row class="buttons-row h-100">
            <v-col
              v-for="(button, index) in buttonsConfig"
              :key="index"
              :class="`button-col button-col-${index}`"
              :cols="12 / buttonsConfig.length"
            >
              <ServiceCard :button="button" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style lang="scss">
.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}
.data-row {
  .card-img {
    width: 80px;
    height: 45px;
  }
  .card-big {
    height: 210px;
  }
  .card-small {
    height: 100px;
    &.top {
      margin-bottom: 10px;
    }
  }
  .purse-small {
    font-size: 1.4rem;
  }
}
.is-cordova {
  .prima-card-row {
    .card-big {
      height: 230px;
    }
  }
}
</style>
<script>
import ServiceCard from "@/components/categoryBlock/elements/ServiceCard.vue";

import categoryBlockType from "./categoryBlockType";
import pointsMixins from "@/mixins/pointsMixins";

import get from "lodash/get";
import VueBarcode from "vue-barcode";
import { mapGetters } from "vuex";

export default {
  name: "PrimaCardRow",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  data() {
    return {
      flipped: false
    };
  },
  mixins: [categoryBlockType, pointsMixins],
  components: { ServiceCard, barcode: VueBarcode },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    fidelityCard() {
      return get(this.cart, "user.fidelityCard");
    },
    hasRequestedFidelityCard() {
      return (
        this.cart &&
        this.cart.user &&
        this.cart.user.person &&
        this.cart.user.person.personInfos.some(info => {
          return info.personInfoTypeId === "4" && info.value === "1";
        })
      );
    },
    hasFidelityCard() {
      if (this.cart && this.cart.user && this.cart.user.fidelityCard) {
        return true;
      } else {
        return false;
      }
    },

    buttonsConfig() {
      return get(
        this.config,
        "metaData.categoryblocktype_PrimaCardRow.BUTTONS_CONFIG.buttons"
      );
    }
  },
  methods: {
    toggleCard() {
      this.flipped = !this.flipped;
    }
  }
};
</script>
