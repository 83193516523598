<template>
  <div class="select-options-selector">
    <v-select
      v-for="selectOption in selectOptionsArray"
      :key="selectOption.name"
      v-model="selectModel[selectOption.name]"
      outlined
      dense
      :items="selectOption.options"
      :label="selectOption.name"
      item-text="key"
      item-value="value"
      height="37"
      hide-details
      return-object
      @click.prevent.stop="setSelectOptions"
      @change="onOptionChanged(selectOption)"
    ></v-select>
  </div>
</template>
<style lang="scss">
// .selectOptions.v-btn.v-btn--outlined {
//   border-color: var(--v-grey-base) !important;
// }
.select-options-selector {
  height: 37px;
  .v-select.v-input.v-input--is-label-active {
    .v-select__slot {
      .v-select__selections {
        min-width: 100px;
      }
    }
  }
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 37px;
  }
}
</style>
<script>
export default {
  name: "SelectedOptionsMenu",
  props: {
    product: { type: Object, required: true },
    selectOptionsArray: { type: Array, required: false },
    size: { type: String, default: "small" }
  },
  components: {},
  data() {
    return {
      selectModel: [],
      componentKey: 0,
      key: 0
    };
  },
  methods: {
    onOptionChanged(selectOption) {
      this.$emit("onOptionChanged", {
        selectOption: selectOption,
        option: this.selectModel[selectOption.name]
      });
    },
    setSelectOptions() {
      this.$emit("setSelectOptions");
    }
  }
};
</script>
