<template>
  <div class="delivery-map">
    <GmapMap
      ref="mapRef"
      :center="{
        lat: 44.3948,
        lng: 8.9454
      }"
      :zoom="10"
      :style="{ width: mapWidth, height: mapHeight }"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
        ...mapStyle
      }"
    >
      <GmapCluster :styles="clusterStyles">
        <GmapMarker
          :ref="'warehousMarker_' + address.addressId"
          v-for="(address, i) in addressList"
          :key="address.addressId"
          :position="
            google &&
              new google.maps.LatLng(address.latitude, address.longitude)
          "
          :clickable="true"
          icon="/pdv/pin.png"
          @click="toggleInfoWindow(address, i)"
        />
      </GmapCluster>
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick.stop="infoWinOpen = false"
      >
        {{ selectedAddress }}
        Contenuto
      </gmap-info-window>
    </GmapMap>
  </div>
</template>
<style lang="scss">
.delivery-map {
}
</style>
<script>
import { gmapApi } from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";

export default {
  name: "DeliveryMap",
  components: {
    GmapCluster
  },
  props: {
    addressList: { type: Array, required: true }
  },
  data() {
    return {
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedAddress: null,

      clusterStyles: [
        {
          textColor: "#FFFFFF",
          url: "/pdv/map_clusters/m1.png",
          height: 52,
          width: 53
        },
        {
          textColor: "#FFFFFF",
          url: "/pdv/map_clusters/m2.png",
          height: 55,
          width: 56
        },
        {
          textColor: "#FFFFFF",
          url: "/pdv/map_clusters/m3.png",
          height: 65,
          width: 66
        },
        {
          textColor: "#FFFFFF",
          url: "/pdv/map_clusters/m4.png",
          height: 77,
          width: 78
        },
        {
          textColor: "#FFFFFF",
          url: "/pdv/map_clusters/m5.png",
          height: 89,
          width: 90
        }
      ],
      mapStyle: {
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5"
              }
            ]
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161"
              }
            ]
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#f5f5f5"
              }
            ]
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#bdbdbd"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#dadada"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161"
              }
            ]
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5"
              }
            ]
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#c9c9c9"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          }
        ]
      }
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "500px" : "750px";
    }
  },
  methods: {
    toggleInfoWindow(address) {
      this.infoWindowPos = {
        lat: address.latitude,
        lng: address.longitude
      };

      let infoWindowBtn = `<a class='v-btn v-btn--text primary--text v-size--small' target='system' href='https://www.google.it/maps/dir//${address.addressName}@/${address.latitude},${address.longitude}'>
            COME ARRIVARE
          </a><span width="10px"></span> <a class='v-btn primary v-size--small' href='/supermercati/${address.addressId}'>
            VAI ALLA SCHEDA
          </a>`;

      this.infoOptions.content = `<div id='infoWindowDialog' style='font-family: Open Sans, arial, sans-serif'>
          <h3>
            ${address.addressName}
          </h3>
          <div class='mb-1'>
            ${address.address1} ${address.addressNumber} <br/>
            ${address.city} ( ${address.province} ) ${address.postalcode}
          </div>
          ${infoWindowBtn}
        </div>`;

      //check if its the same marker that was selected if yes toggle
      if (
        this.selectedAddress &&
        this.selectedAddress.addressId == address.addressId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedAddress = address;
      }
    }
  },
  mounted() {}
};
</script>
