<template>
  <div
    v-if="giftCodes && giftCodes.length > 0"
    class="slider widget-slider"
    :class="{ 'container container--fluid': container }"
    :key="componentKey"
  >
    <swiper :options="swiperOption" ref="swiperRef" class="h-inherit">
      <swiper-slide v-for="giftCode in giftCodes" :key="giftCode.id">
        <GiftCodeWidgetCard
          :giftCertificate="giftCode"
          @add="reload"
          @remove="reload"
        />
      </swiper-slide>
    </swiper>
    <!-- <swiper-paginator
      :showArrows="showArrows"
      :paginationClass="paginationClass"
      :length="giftCodes.length"
    /> -->
  </div>
  <ServiceDiscovery v-else-if="!loading" />
</template>
<style lang="scss">
.slider.widget-slider {
  height: 100%;
}
</style>
<script>
import ServiceDiscovery from "@/components/categoryBlock/ServiceDiscovery.vue";
import GiftCodeWidgetCard from "./elements/GiftCodeWidgetCard.vue";
// import SwiperPaginator from "@/components/SwiperPaginator.vue";

import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

import userService from "~/service/userService";

import { mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "WidgetSlider",
  props: {
    container: { type: Boolean, default: true },
    paginationClass: { type: String, default: "swiper-pagination-widget" },
    cols: { type: Number, default: 1 },
    sm: { type: Number, default: 1 },
    md: { type: Number, default: 1 },
    lg: { type: Number, default: 1 },
    showArrows: { type: Boolean, default: true },
    showBullets: { type: Boolean, default: true }
  },
  components: {
    GiftCodeWidgetCard,
    // SwiperPaginator,
    ServiceDiscovery
  },
  mixins: [login, deliveryReactive],
  data() {
    return {
      loading: true,
      giftCodes: null,
      componentKey: 0,
      swiperRef: null,
      swiperOption: {
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        pagination: {
          el: `#pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#prev-${this.paginationClass}`,
          nextEl: `#next-${this.paginationClass}`
        },
        breakpoints: {
          600: {
            slidesPerView: this.cols
          },
          960: {
            slidesPerView: this.sm
          },
          1264: {
            slidesPerView: this.md
          },
          1904: {
            slidesPerView: this.lg
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  methods: {
    async reload() {
      try {
        this.loading = true;
        let autoAssignedGifts = await userService.listGiftCertificate("auto");
        let manualAssignedGifts = await userService.listGiftCertificate(
          "manual"
        );
        this.giftCodes = [...autoAssignedGifts, ...manualAssignedGifts].filter(
          gift =>
            !get(
              gift,
              "categoryProposal.metaData.category_proposal_type.HIDE_IN_HOME"
            ) == true
        );
      } catch (e) {
        this.giftCodes = [];
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.reload();
    }
  }
  // watch: {
  //   giftCodes() {
  //     this.componentKey += 1;
  //     this.$refs.swiperRef.swiperInstance?.slideTo(0);
  //   }
  // }
};
</script>
