<template>
  <!-- this type of banner is vertical on mobile resolution and horizontal on desktop -->
  <v-card
    rounded="lg"
    :href="link"
    @click.prevent="handleLink"
    :style="getStyle('BACKGROUND_COLOR', 'TEXT_COLOR')"
    class="proposal-card-vertical-responsive"
    v-intersect.once="handleView"
  >
    <v-row no-gutters class="proposal-card-vertical-responsive-row">
      <v-col
        cols="12"
        sm="6"
        :lg="smallCard ? '5' : '8'"
        :xl="smallCard ? '4' : '8'"
        :order-sm="isCardInverted ? 1 : 2"
        order="1"
        :style="getStyle('BG_ICON_COLOR')"
        class="img-column"
      >
        <v-img
          class="image-card"
          :src="imgSrc"
          :alt="proposal.imgAlt"
          :title="proposal.imgDescription"
          :contain="
            $ebsn.meta(
              proposal,
              'metaData.category_proposal_type.IMAGE_CONTAIN',
              false
            )
          "
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        :lg="smallCard ? '7' : '4'"
        :xl="smallCard ? '8' : '4'"
        :order-sm="isCardInverted ? 2 : 1"
        order="2"
        class="d-flex flex-column content-column"
      >
        <v-card-title
          v-if="proposal.descr"
          v-html="proposal.descr"
        ></v-card-title>
        <v-card-text v-if="proposal.content" v-html="proposal.content">
        </v-card-text>
        <v-spacer></v-spacer>
        <!-- v-if="link && !$ebsn.meta(proposal, 'category_proposal_type.HIDE_BTN')" -->
        <v-card-actions
          v-if="
            link &&
              $ebsn.meta(proposal, 'category_proposal_type.BTN_TEXT', false)
          "
          :class="
            $ebsn.meta(
              proposal,
              'category_proposal_type.BTN_POSITION',
              ' justify-start justify-sm-end '
            )
          "
        >
          <!-- class="justify-end justify-sm-start" -->
          <v-btn
            :href="link"
            @click.prevent="handleLink"
            depressed
            large
            color="primary"
            :outlined="
              $ebsn.meta(proposal, 'category_proposal_type.BTN_OUTLINED', false)
            "
            :style="
              $ebsn.meta(proposal, 'category_proposal_type.BTN_OUTLINED', false)
                ? getStyle('BACKGROUND_COLOR', 'TEXT_COLOR')
                : getStyle('TEXT_COLOR', 'BACKGROUND_COLOR')
            "
          >
            {{
              $ebsn.meta(
                proposal,
                "category_proposal_type.BTN_TEXT",
                "Visualizza"
              )
            }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.proposal-card-vertical-responsive {
  overflow: hidden;
  height: 100%;
  .image-card {
    height: initial;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      height: 100%;
    }
  }
  .header {
    background-color: var(--v-secondary-base);
    color: var(--v-white-base);
    width: fit-content;
    border-radius: $border-radius-root;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 800;
    &.promo-header {
    }
  }
  .row {
    height: 100%;
  }
  .btn-block {
    .v-btn {
      // same style as v-btn-block
      display: flex;
      flex: 1 0 auto;
      min-width: 100% !important;
      max-width: auto;
    }
  }
}
</style>
<script>
import banner from "~/mixins/banner";

import get from "lodash/get";

export default {
  name: "ProposalCardVerticalResponsive",
  props: {
    proposal: { type: Object, required: true },
    smallCard: { type: Boolean, default: false }
  },
  mixins: [banner],
  computed: {
    imgSrc() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(this.proposal, prop, this.proposal.img);
    },
    isCardInverted() {
      return this.$ebsn.meta(
        this.proposal,
        "category_proposal_type.INVERTED",
        false
      );
    }
  },
  methods: {
    getStyle(backgroundPath, colorPath) {
      let style = {};

      if (backgroundPath) {
        let backgroundColor = get(
          this.proposal,
          `metaData.category_proposal_type.${backgroundPath}`
        );
        if (backgroundColor) {
          style.backgroundColor = backgroundColor + " !important";
        }
      }
      if (colorPath) {
        let textColor = get(
          this.proposal,
          `metaData.category_proposal_type.${colorPath}`
        );
        if (textColor) {
          style.color = textColor + " !important";
        }
      }

      return style;
    }
  }
};
</script>
