<template>
  <v-alert
    class="open-alert text-center rounded"
    :color="alertColor"
    :max-width="maxWidth"
    :outlined="outlined"
    dense
  >
    <component :is="small ? 'small' : 'div'">{{ label }}</component>
  </v-alert>
</template>
<style lang="scss" scoped>
.open-alert {
  padding: 2px 4px;
  font-size: 0.9rem;
}
</style>
<script>
import { isOpenRightNow } from "@/warehouseHoursHelper";

export default {
  name: "isOpenRightNow",
  props: {
    serviceHours: { type: Object, required: true },
    color: { type: String, required: false },
    small: { type: Boolean, required: false, default: false },
    maxWidth: { type: String, required: false, default: "80" },
    outlined: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      open: false,
      label: null,
      alertColor: null,
      offsetDay: 1
    };
  },
  mounted() {
    this.open = isOpenRightNow(this.serviceHours, this.offsetDay);

    this.label = this.open
      ? this.$t("warehouses.open")
      : this.$t("warehouses.closed");
    if (this.color) {
      this.alertColor = this.color;
    } else {
      this.alertColor = this.open ? "primary" : "error";
    }
  }
};
</script>
