<template>
  <v-card
    class="service-card rounded d-flex flex-column justify-center align-center h-100"
    :href="button.url"
    @click.prevent="clicked"
  >
    <img
      class="service-img"
      :src="button.imgUrl"
      :alt="button.imgAlt"
      :title="button.imgAlt"
    />
    <div
      class="btn-label font-weight-bold"
      v-if="button.text"
      v-html="button.text"
    ></div>
  </v-card>
</template>
<style lang="scss">
.service-card {
  .service-img {
    height: 50px;
    margin-bottom: 14px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 15px;
    .service-img {
      height: 40px;
    }
  }
  @media #{map-get($display-breakpoints, 'xl-only')} {
    .service-img {
      height: 60px;
    }
  }
  .btn-label {
    text-align: center;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "ServiceButton",
  mixins: [clickHandler],
  props: {
    button: { type: Object, required: true }
  }
};
</script>
