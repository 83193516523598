var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.products.length > 0)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.handleView),expression:"handleView",modifiers:{"once":true}}],staticClass:"product-list-slider",style:(_vm.style),attrs:{"id":`product-list-slider-${_vm.config.categoryBlockId}`}},[_c('ebsn-style',{attrs:{"target":_vm.config,"path":`categoryblocktype_${_vm.name}.CSS_STYLE`,"styleWrapper":`#product-list-slider-${_vm.config.categoryBlockId}`}}),_c('v-container',{class:{ 'pa-0': _vm.container === false },attrs:{"fluid":""}},[(_vm.showMore && !_vm.showCard)?_c('div',{staticClass:"title-row d-flex flex-column flex-md-row justify-md-space-between align-start align-md-center mb-5"},[_c('ebsn-meta',{style:(_vm.titleColor ? 'color: ' + _vm.titleColor : ''),attrs:{"target":_vm.config,"path":`categoryblocktype_${_vm.name}.TITLE`,"tag":"h2"}}),(
          !_vm.$ebsn.meta(this.config, 'categoryblocktype_' + _vm.name + '.HIDE_LINK')
        )?_c('v-btn',{staticClass:"align-self-end align-self-md-center",attrs:{"link":"","text":"","to":{
          name: 'Category',
          params: {
            pathMatch: _vm.$ebsn.meta(
              this.config,
              'categoryblocktype_' + _vm.name + '.CATEGORY.slug'
            )
          }
        }}},[_c('span',{staticClass:"text-underline"},[_vm._v(_vm._s(_vm.$ebsn.meta( this.config, "categoryblocktype_" + _vm.name + ".CATEGORY.BUTTON_TEXT", _vm.$t("categoryBlock.productListSlider.showAll") ))+" ")]),_c('v-icon',{staticClass:"ml-3"},[_vm._v("$arrowForward")])],1):_vm._e()],1):_vm._e(),_c('ebsn-meta',{attrs:{"target":_vm.config,"path":`categoryblocktype_${_vm.name}.DESCRIPTION`,"tag":"div"}}),_c('div',{staticClass:"slider product-slider"},[_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},[(_vm.showCard)?_c('swiper-slide',{staticClass:"swiper-slide-card"},[_c('product-slider-card',{attrs:{"img":_vm.$ebsn.meta(
                _vm.config,
                'categoryblocktype_' + _vm.name + '.CARD_IMAGE.location'
              ),"text":_vm.$ebsn.meta(_vm.config, 'categoryblocktype_' + _vm.name + '.CARD_TEXT'),"link":{
              name: 'Category',
              params: {
                pathMatch: _vm.$ebsn.meta(
                  this.config,
                  'categoryblocktype_' + _vm.name + '.CATEGORY.slug'
                )
              }
            },"linkLabel":_vm.$ebsn.meta(_vm.config, 'categoryblocktype_' + _vm.name + '.BTN_TEXT'),"backgroundColor":_vm.$ebsn.meta(
                _vm.config,
                'categoryblocktype_' + _vm.name + '.CARD_BG_COLOR'
              )}})],1):_vm._e(),_vm._l((_vm.products),function(product){return _c('swiper-slide',{key:product.productId},[_c('ProductCard',{attrs:{"product":product}})],1)})],2),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_' + _vm.name + '.SHOW_BULLETS',
            true
          ) && _vm.products.length > 1
        )?_c('div',{class:`swiper-pagination product-list-pagination-${_vm.config.categoryBlockId}`,attrs:{"id":`product-list-pagination-${_vm.config.categoryBlockId}`}}):_vm._e(),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_' + _vm.name + '.SHOW_ARROWS',
            false
          ) && _vm.products.length > 1
        )?_c('div',{staticClass:"swiper-button-prev",attrs:{"id":`product-list-slider-prev-${_vm.config.categoryBlockId}`}}):_vm._e(),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_' + _vm.name + '.SHOW_ARROWS',
            false
          ) && _vm.products.length > 1
        )?_c('div',{staticClass:"swiper-button-next",attrs:{"id":`product-list-slider-next-${_vm.config.categoryBlockId}`}}):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }