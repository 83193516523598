<template functional>
  <div class="mr-1 vendor-icon-container">
    <img
      aria-hidden
      :src="props.vendor.url"
      :alt="props.vendor.name"
      :title="props.vendor.name"
      class="vendor-icon"
    />
  </div>
</template>
<style lang="scss">
.vendor-icon-container {
  .vendor-icon {
    height: 45px;
    width: auto;
  }
}
</style>
<script>
export default {
  name: "ProductVendor",
  props: {
    vendor: { type: Object }
  }
};
</script>
