<template>
  <v-card
    class="product-slider-card"
    rounded="sm"
    elevation="0"
    flat
    :to="link"
    :style="
      this.backgroundColor ? `background-color: ${this.backgroundColor}` : ''
    "
  >
    <v-img
      :src="img"
      alt="Immagine categoria"
      class="product-slider-card-image"
    />
    <v-card-text v-if="text" v-html="text"> </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions v-if="link && linkLabel">
      <v-btn
        :to="link"
        @click.prevent="handleLink"
        color="primary"
        large
        block
        depressed
        >{{ linkLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.product-slider-card {
  display: flex;
  flex-direction: column;
}
</style>
<script>
export default {
  name: "ProductSliderCard",
  props: {
    img: { type: String, required: true },
    text: { type: String, required: true },
    link: { type: [String, Object], required: false },
    linkLabel: { type: String, required: false },
    backgroundColor: { type: String, required: false }
  }
};
</script>
