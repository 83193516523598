<template functional>
  <div class="mr-1 product-class">
    <img
      aria-hidden
      :src="props.pClass.iconSource"
      :alt="props.pClass.name"
      :title="props.pClass.name"
      class="product-class-icon"
    />
  </div>
</template>
<style lang="scss">
.product-class {
  .product-class-icon {
    height: 45px;
    width: auto;
  }
}
</style>
<script>
export default {
  name: "ProductClass",
  props: {
    pClass: { type: Object }
  }
};
</script>
