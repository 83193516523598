<template>
  <div class="navbar-delivery-service d-flex align-center">
    <template v-if="isAddressAssigned">
      <img
        class="change-service-img mr-2"
        alt="Logo servizio"
        aria-label="Logo servizio scelto"
        :src="editIconImg"
      />
      <span class="selected-address-text">
        {{ shippingAddress }}
      </span>
    </template>
    <span v-else>
      {{ $t("navbar.navbarSiteSelection.addressAutoassigned") }}
    </span>
    <v-btn
      link
      text
      class="open-address-selector-btn"
      :to="{
        name: 'DeliveryServiceView',
        query: { back: this.$route.fullPath }
      }"
    >
      {{ $t("navbar.changeServices") }}
    </v-btn>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "NavbarDeliveryService",
  props: {
    shippingAddress: { type: String }
  },
  computed: {
    ...mapGetters({
      getDeliveryServiceId: "cart/getDeliveryServiceId",
      isAddressAssigned: "cart/isAddressAssigned"
    }),
    editIconImg() {
      return this.$t(
        "navbar.service." + this.getDeliveryServiceId + ".editIcon"
      );
    }
  }
};
</script>
