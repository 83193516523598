<template functional>
  <div
    class="product-price"
    :class="[
      { 'has-promo': $options.isPromoPrice(props.product) },
      { 'is-list text-md-center': props.isList }
    ]"
  >
    <!-- Se non ci sono promozioni per il prodotto o se è esaurito -->
    <div class="promo-price" v-if="$options.isPromoPrice(props.product)">
      <div class="old-price">
        <span class="price">
          <i18n-n
            :value="props.product.priceStandardDisplay"
            format="currency"
            tag="span"
            class="formatted-price"
          >
            <template v-slot:literal></template>
            <template v-slot:currency="slotProps">
              <span class="currency small">{{ slotProps.currency }}</span>
            </template>
          </i18n-n>
        </span>
      </div>
      <div class="new-price">
        <span class="price">
          <i18n-n
            :value="props.product.priceDisplay"
            format="currency"
            tag="span"
            class="formatted-price"
          >
            <template v-slot:literal></template>
            <template v-slot:currency="slotProps">
              <span class="currency">{{ slotProps.currency }}</span>
            </template>
          </i18n-n>
        </span>
      </div>
    </div>
    <!-- v-else-if="!props.product.warehousePromo" -->
    <div class="no-promo-price" v-else>
      <div class="price_block">
        <div v-if="props.product.priceDisplay" class="cur-price">
          <i18n-n
            :value="props.product.priceDisplay"
            format="currency"
            tag="span"
            class="formatted-price"
          >
            <template v-slot:literal></template>
            <template v-slot:currency="slotProps">
              <span class="currency">{{ slotProps.currency }}</span>
              <span
                class="weight-unit"
                v-if="props.product.productInfos.TIPOLOGIA != 'Pezzo'"
                >/{{ props.product.weightUnitDisplay }}
              </span>
            </template>
          </i18n-n>
        </div>
      </div>
    </div>

    <span
      class="price-um"
      v-if="
        props.product.productInfos &&
          props.product.productInfos.TIPOLOGIA == 'Pezzo' &&
          props.product.priceUmDisplay &&
          !props.isList &&
          props.product.productInfos.PRODUCT_NATURE_ID != 8
      "
    >
      <i18n-n
        :value="props.product.priceUmDisplay"
        format="currency"
        tag="span"
        class="formatted-price small"
      >
        <template v-slot:currency="slotProps">
          <span class="currency small">{{ slotProps.currency }}</span>
          <span class="text-lowercase"
            >/{{ props.product.weightUnitDisplay }}</span
          >
        </template>
      </i18n-n>
    </span>
  </div>
</template>
<style lang="scss">
.product-price:not(.is-list) {
  height: 78px;
  position: relative;
  border: 2px solid var(--v-primary-base);
  border-radius: 10px 10px 10px 0;
  &.has-promo {
    border: none;
  }
  .no-promo-price,
  .promo-price {
    font-size: 2rem;
    font-weight: 800;
  }
  .currency {
    font-size: 1.1rem;
    font-weight: 500;
    &.small {
      font-size: 0.875rem;
    }
  }
  .weight-unit {
    text-transform: lowercase;
    font-size: 1.1rem;
    font-weight: normal;
  }
  .no-promo-price {
    color: var(--v-primary-base);
    .cur-price {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 74px;
    }
  }
  .promo-price {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    .old-price {
      border-radius: $border-radius-root $border-radius-root 0 0;
      height: 24px;
      font-size: 0.875rem;
      font-weight: 800;
      color: var(--v-default-base);
      text-decoration: line-through;
      // background-color: var(--v-primary-darken1);
      background-color: var(--v-primary-base);
      .price {
        display: block;
        margin-top: 4px;
      }
    }
    .new-price {
      border-radius: 0 0 $border-radius-root 0;
      height: 54px;
      width: 100%;
      background-color: var(--v-primary-base);
      .price {
        display: block;
      }
    }
  }
  .price-um {
    position: absolute;
    font-size: 0.875rem;
    left: 0;
    top: 76px;
    width: 100%;
    text-align: center;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10px;
    width: 0;
    height: 0;
    border: 24px solid transparent;
    border-top-color: var(--v-primary-base);
    border-bottom: 0;
    border-left: 0;
    margin-left: -12px;
    margin-bottom: -24px;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10px;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: white;
    border-bottom: 0;
    border-left: 0;
    margin-left: -10px;
    margin-bottom: -19px;
  }
}
.product-price.is-list {
  @media #{map-get($display-breakpoints, 'md-only')} {
    text-align: center;
  }
  .promo-price {
    .old-price {
      text-decoration: line-through;
      font-size: 0.875rem;
    }
  }
  .weight-unit {
    text-transform: lowercase;
    font-size: 1rem;
    font-weight: normal;
  }
  .cur-price,
  .new-price {
    font-size: 1.125rem;
    font-weight: bold;
    color: var(--v-primary-base);
  }
}
.has-promo.product-price:before {
  left: 12px;
}
.has-promo.product-price:after {
  left: 12px;
  border-top-color: var(--v-primary-base);
}
</style>
<script>
export default {
  name: "props.productPrice",
  props: {
    product: { type: Object, required: true },
    isList: { type: Boolean, required: false, default: false }
  },
  isPromoPrice(prod) {
    return prod.priceStandardDisplay ? true : false;
  }
};
</script>
