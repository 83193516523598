<template>
  <div
    v-if="isAuthenticated && favWarehouse"
    :id="`favourite-wh-${config.categoryBlockId}`"
    class="favourite-wh-row green lighten-2"
    :style="style"
  >
    <ebsn-style
      :target="config"
      path="metaData.categoryblocktype_FavouriteWh.CSS_STYLE"
      :styleWrapper="`#favourite-wh-${config.categoryBlockId}`"
    />
    <v-container fluid class="py-0">
      <ebsn-meta
        :target="config"
        path="categoryblocktype_FavouriteWh.TITLE"
        tag="h2"
        :style="
          `color: ${$ebsn.meta(
            this.config,
            'categoryblocktype_FavouriteWh.TITLE_COLOR',
            'currentColor'
          )}; `
        "
      />
      <ebsn-meta
        :target="config"
        path="categoryblocktype_FavouriteWh.DESCRIPTION"
        tag="div"
      />

      <v-row no-gutters>
        <v-col cols="12" md="5" lg="4">
          <v-card
            flat
            tile
            v-if="favWarehouse == -1"
            class="no-fav-wh-card primary pa-4 h-100"
          >
            <v-card-title class="no-fav-wh-title text-h3 default--text">
              {{ $t("favouriteWhBlock.noFavWhTitle") }}
            </v-card-title>
            <v-card-text class="no-fav-wh-descr default--text">
              {{ $t("favouriteWhBlock.noFavWhDescr") }}
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                min-width="200"
                depressed
                outlined
                :to="{
                  name: 'ProfileUpdate',
                  query: {
                    navToRefName: 'favouriteWh'
                  }
                }"
              >
                {{ $t("favouriteWhBlock.noFavWhBtn") }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <WarehouseInfo
            :warehouse="favWarehouse"
            :key="favWarehouse.warehouseId"
          />
        </v-col>
        <v-col cols="12" md="7" lg="8">
          <div class="warehouse-leaflet-slider pa-4">
            <h3>
              {{ $t("favouriteWhBlock.flyersTitle") }}
            </h3>
            <leaflet-slider :parentWarehouseId="favWarehouse.warehouseId" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import WarehouseInfo from "@/components/warehouse/WarehouseInfo.vue";
import LeafletSlider from "@/components/leaflet/LeafletsSlider.vue";
import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import AddressService from "~/service/addressService";

import { mapState, mapGetters } from "vuex";

export default {
  name: "FavouriteWh",
  props: {
    config: { type: Object, required: true }
  },
  components: { WarehouseInfo, LeafletSlider },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      favWarehouse: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  methods: {
    async handleFavWhChange(wh) {
      await this.fetchFavWarehouse(wh);
    },
    async fetchFavWarehouse(warehouseId) {
      try {
        this.loading = true;
        if (warehouseId) {
          let res = await AddressService.getWarehouseById(warehouseId);
          this.favWarehouse = res;
        } else {
          this.favWarehouse = -1;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.fetchFavWarehouse(this.cart.user.defaultStoreAddress.addressId);
    global.EventBus.$on("fav-wh-changed", this.handleFavWhChange);
  }
};
</script>
<style lang="scss"></style>
