<template>
  <div class="d-inline-block">
    <v-btn link color="transparent" depressed @click="dialog = true"
      ><v-icon>$edit</v-icon></v-btn
    >
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title class="font-weight-bold">{{
            $t("navbar.favoutireWh")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-select
            ref="favouriteWh"
            v-model="warehouse"
            :items="warehouses"
            item-text="address.addressName"
            item-value="address.addressId"
            :label="`${$t('register.user.favouriteWh')}`"
            :placeholder="$t('register.user.favouriteWh')"
            hide-details
            filled
            dense
            outlined
            flat
          ></v-select>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="setFavWarehouse">{{
            $t("navbar.saveFavWarehouse")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss"></style>
<script>
import baskoCustomService from "@/service/baskoCustomService";
import AddressService from "~/service/addressService";
export default {
  name: "FavWarehouseSelector",
  data() {
    return {
      warehouse: null,
      warehouses: null,
      dialog: false
    };
  },
  methods: {
    async getWarehouses() {
      let res = await AddressService.findWarehouse();
      if (res && res.warehouses) {
        this.warehouses = res.warehouses;
      }
    },
    async setFavWarehouse() {
      const res = await baskoCustomService.setFavWarehouse(this.warehouse);
      if (res.response.status === 0) {
        this.dialog = false;
        global.EventBus.$emit(
          "fav-wh-changed",
          res.data.user.defaultStoreAddress.addressId
        );
      }
    }
  },
  async mounted() {
    await this.getWarehouses();
  }
};
</script>
