<template>
  <div
    v-if="products.length > 0"
    :id="`product-tab-${config.categoryBlockId}`"
    class="product-tag"
    :style="style"
    v-intersect.once="handleView"
  >
    <v-container fluid>
      <ebsn-meta
        :target="config"
        path="categoryblocktype_ProductTab.TITLE"
        tag="h2"
        :style="
          `color: ${$ebsn.meta(
            this.config,
            'categoryblocktype_ProductTab.TITLE_COLOR',
            'currentColor'
          )}; `
        "
      />
      <ebsn-meta
        :target="config"
        path="categoryblocktype_categoryblocktype_ProductTab.DESCRIPTION"
        tag="div"
      />

      <v-tabs
        v-model="selectedProduct"
        :color="$ebsn.meta(config, 'categoryblocktype_ProductTab.TABS_COLOR')"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="product in products" :key="product.productId">
          {{ product.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedProduct">
        <v-tab-item v-for="product in products" :key="product.productId">
          <product-card-big :product="product" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";

import Vue from "vue";
import ProductCardBig from "./elements/ProductCardBig.vue";

export default {
  components: { ProductCardBig },
  name: "ProductSlider",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  mixins: [deliveryReactive],
  data() {
    return {
      products: [],
      selectedProduct: null
    };
  },
  methods: {
    async handleView() {
      return null;
    },
    async reload() {
      this.products = [];

      let categoryId = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_ProductTab.CATEGORY.categoryId"
      );
      if (categoryId) {
        let response = await ProductService.search({
          parent_category_id: categoryId,
          page_size: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_ProductTab.PRODUCT_LIMIT",
            12
          )
        });
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
        this.$emit("loaded", this.products ? this.products.length : 0);
      }
    }
  },
  async created() {
    await this.reload();
  }
};
</script>
