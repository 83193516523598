<template>
  <div class="address-selector">
    <ebsn-meta
      :target="category"
      path="category_info.TITLE"
      class="text-center"
      tag="h1"
    ></ebsn-meta>
    <ebsn-meta
      :target="category"
      path="category_info.DESCRIPTION"
      class="text-center mt-2"
    ></ebsn-meta>
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
      :container="false"
    />

    <div class="address-list-container">
      <!-- <v-text-field
        hide-details
        v-model="searchText"
        clearable
        outlined
        dense
        :label="
          $t('navbar.service.' + selectedDeliveryServiceId + '.searchLabel')
        "
      >
        <v-icon slot="append" color="red">
          $search
        </v-icon>
      </v-text-field> -->

      <div
        class="address-counter font-weight-bold d-flex justify-space-between pt-6 mb-4"
      >
        {{
          $t(
            `addressSelector.deliveryService.${selectedDeliveryServiceId}.addressName`
          )
        }}
        <span class="primary--text">
          {{ filteredAddresses.length }} {{ $t("addressSelector.addressFind") }}
        </span>
      </div>

      <v-row>
        <v-col cols="12" :md="showDeliveryMap ? 6 : 12">
          <v-row class="address-list">
            <v-col
              cols="12"
              :md="showDeliveryMap ? 12 : 6"
              v-for="address in filteredAddresses"
              :key="address.addressId"
            >
              <v-card
                outlined
                class="address-card h-100"
                :class="{
                  'selected-address':
                    address.addressId == cart.shippingAddress.addressId
                }"
              >
                <v-card-title class="address-title primary--text">
                  {{ address.addressName }}
                </v-card-title>
                <v-card-subtitle class="address-subtitle">
                  {{
                    $t(
                      "navbar.address." + address.addressTypeId + ".list",
                      address
                    )
                  }}
                </v-card-subtitle>
                <v-spacer />
                <v-card-actions class="justify-end">
                  <v-tooltip top v-if="address.addressType == 'work'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="shop-at-work mr-4" v-bind="attrs" v-on="on"
                        >$work</v-icon
                      >
                    </template>
                    <span>{{ $t("addressSelector.shopAtWork") }}</span>
                  </v-tooltip>

                  <v-tooltip
                    top
                    v-if="
                      address.addressId == cart.user.defaultStoreAddressId ||
                        address.addressId == cart.user.defaultDeliveryAddressId
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="favourite-address mr-4"
                        v-bind="attrs"
                        v-on="on"
                        >$favourite</v-icon
                      >
                    </template>
                    <span>{{ $t("addressSelector.favouriteAddress") }}</span>
                  </v-tooltip>

                  <v-btn
                    v-if="address.addressType == 'home'"
                    icon
                    @click.stop="edit(address)"
                  >
                    <v-icon>$edit</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="address.addressType == 'home'"
                    icon
                    @click.stop="remove(address)"
                  >
                    <v-icon>$delete</v-icon>
                  </v-btn>

                  <v-btn
                    color="primary"
                    large
                    depressed
                    @click="setAddress(address)"
                  >
                    {{ $t("addressSelector.selectBtn") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              v-if="selectedDeliveryServiceId == 5"
              cols="12"
              :md="showDeliveryMap ? 12 : 6"
            >
              <!-- if locker add link to shop@work -->
              <v-card outlined>
                <v-card-title class="primary--text">{{
                  $t("addressSelector.shopAtWorkCard.title")
                }}</v-card-title>
                <v-card-subtitle>{{
                  $t("addressSelector.shopAtWorkCard.subtitle")
                }}</v-card-subtitle>
                <v-card-actions class="justify-space-between">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="shop-at-work mr-4" v-bind="attrs" v-on="on"
                        >$work</v-icon
                      >
                    </template>
                    <span>{{ $t("addressSelector.shopAtWork") }}</span>
                  </v-tooltip>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    large
                    depressed
                    :to="{ name: 'WorkAndShop' }"
                  >
                    {{ $t("addressSelector.shopAtWorkCard.button") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="showDeliveryMap" cols="12" md="6">
          <DeliveryMap :addressList="filteredAddresses" />
        </v-col>
      </v-row>

      <div class="bottom-action-container mt-3">
        <v-btn
          v-if="
            (selectedDeliveryServiceId == 2 ||
              selectedDeliveryServiceId == 4) &&
              filteredAddresses.length == 0
          "
          large
          depressed
          color="primary"
          class="mr-3"
          :to="{ name: 'Page', params: { postName: 'servizi-spesa' } }"
          @click="$emit('submit', false)"
          align="center"
        >
          {{ $t("addressSelector.discoverServicesBtn") }}
        </v-btn>

        <v-btn
          color="primary"
          large
          depressed
          v-if="
            selectedDeliveryServiceId == 2 || selectedDeliveryServiceId == 4
          "
          :to="{
            name: 'EditAddresses',
            query: { isRider: selectedDeliveryServiceId == 4 ? true : false }
          }"
          @click="$emit('submit', false)"
        >
          {{ $t("addressSelector.addNewAddressBtn") }}
        </v-btn>
      </div>
    </div>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
      :container="false"
    />
  </div>
</template>
<style lang="scss">
.address-card {
  display: flex;
  flex-direction: column;
}
.address-selector {
  padding: 30px;
  .v-card.v-sheet--outlined.selected-address {
    border-color: var(--v-primary-base);
  }
  // .address-list {
  //   height: 80vh;
  //   overflow-y: auto;
  //   overflow-x: hidden;
  // }
}
</style>
<script>
import DeliveryMap from "@/components/delivery/DeliveryMap.vue";

import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import { mapState } from "vuex";

import get from "lodash/get";

export default {
  name: "AddressSelector",
  components: { DeliveryMap },
  props: {
    deliveryServiceId: { type: String, required: false },
    isNotPopup: { type: Boolean, default: false },
    fetchedCategory: { type: Object, required: false }
  },
  data() {
    return {
      store: {},
      addresses: [],
      loading: null,
      searchText: null,
      category: {}
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    showDeliveryMap() {
      return this.isNotPopup &&
        (this.selectedDeliveryServiceId == 1 ||
          this.selectedDeliveryServiceId == 5)
        ? true
        : false;
    },
    selectedDeliveryServiceId() {
      return this.deliveryServiceId
        ? this.deliveryServiceId
        : this.cart.shippingAddress.deliveryServiceId;
    },
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    filteredAddresses() {
      if (this.searchText) {
        return this.addresses.filter(address => {
          return (
            address.addressName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.address1
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.city.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.addresses;
      }
    }
  },
  methods: {
    async remove(address) {
      let _this = this;
      const res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        _this.addresses = response.addresses;
      }
    },
    edit(address) {
      this.$router.push({
        name: "EditAddresses",
        params: { addressId: address.addressId }
      });
      this.$emit("submit", false);
    },
    async fetchAddresses() {
      let _this = this;
      _this.loading = true;
      _this.searchText = null;
      _this.addresses = await StoreService.getAddressesByDeliveryService(
        this.selectedDeliveryServiceId
      );

      _this.loading = false;
    },
    async setAddress(address) {
      let _this = this;
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId,
        deliveryServiceId: address.deliveryServiceId
      });

      this.loading = false;
      if (data) {
        _this.$emit("submit", true);
      }
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          this.$t(
            `addressSelector.deliveryService.${this.selectedDeliveryServiceId}.slug`
          )
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.fetchAddresses();
    // if (this.fetchedCategory) {
    //   this.category = this.fetchedCategory;
    // } else {
    this.fetchCategory();
    // }
  }
};
</script>
