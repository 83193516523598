<template>
  <!-- this type of banner is vertical on mobile resolution and horizontal on desktop -->
  <v-card
    rounded="lg"
    :href="link"
    @click.prevent="handleLink"
    :style="cardStyle"
    class="proposal-card"
    v-intersect.once="handleView"
  >
    <v-img
      :src="imgSrc"
      :alt="proposal.imgAlt"
      :title="proposal.imgDescription"
    >
      <v-card-title
        v-if="proposal.descr"
        v-html="proposal.descr"
      ></v-card-title>
      <v-card-text
        v-if="proposal.content"
        v-html="proposal.content"
      ></v-card-text>
    </v-img>
  </v-card>
</template>
<style lang="scss">
.proposal-card {
  overflow: hidden;
  height: 100%;
  .v-image {
    height: 100%;
  }
}
</style>
<script>
import banner from "~/mixins/banner";

import get from "lodash/get";

export default {
  name: "ProposalCard",
  props: {
    proposal: { type: Object, required: true },
    smallCard: { type: Boolean, default: false }
  },
  mixins: [banner],
  computed: {
    cardStyle() {
      let style = {};
      let backgroundColor = get(
        this.proposal,
        `metaData.category_proposal_type.BACKGROUND_COLOR`
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      let textColor = get(
        this.proposal,
        `metaData.category_proposal_type.TEXT_COLOR`
      );
      if (textColor) {
        style.color = textColor;
      }

      return style;
    },
    btnStyle() {
      let style = {};
      let backgroundColor = get(
        this.proposal,
        `metaData.category_proposal_type.TEXT_COLOR`
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      let textColor = get(
        this.proposal,
        `metaData.category_proposal_type.BACKGROUND_COLOR`
      );
      if (textColor) {
        style.color = textColor;
      }

      return style;
    },
    imgSrc() {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
