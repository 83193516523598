<template functional>
  <div class="promo-container">
    <div class="promo-box" :class="[props.warehousePromo.view.cssClass]">
      <span
        v-html="props.warehousePromo.view.header"
        class="promo-header"
      ></span>
    </div>
    <div v-html="props.warehousePromo.view.body" class="promo-body"></div>
    <div
      v-if="props.warehousePromo.view.footer"
      v-html="props.warehousePromo.view.footer"
      class="promo-footer"
    ></div>
  </div>
</template>
<style lang="scss">
.promo-container {
  .promo-box {
    background-color: $promo-red;
    color: $white;
    position: relative;
    // width: 61px;
    padding-left: 4px;
    padding-right: 4px;
    height: 36px;
    border-radius: 10px 10px 0 10px;

    .promo-header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    // promo type 1
    &.promo_mxn {
      font-size: 1.4rem;
      font-weight: 900;
    }

    // promo type 4
    &.promo_ottienipunti {
      width: 72px;
      height: 78px;
      &.borsellino-long {
        height: 96px;
      }
      border-radius: 6px;
      margin-bottom: 8px;
      background-color: var(--v-accent-lighten1);
      .promo-header {
        flex-direction: column;
        color: var(--v-default-base);
        font-weight: 800;
        line-height: 1rem;
        font-size: 0.925rem;
        .money-text {
          color: $promo-red;
          margin-top: 3px;
        }
        .borsellino-text {
          font-size: 0.8rem;
          text-align: center;
        }
      }
    }

    // promo type 5
    &.promo_percent.promo_cut_price {
      width: 90px;
      max-width: 90px;
      font-size: 0.925rem;
      text-transform: uppercase;
      font-weight: 900;
    }

    &.promo_sotto.promo_cut_price {
      background-color: $promo-light-blue;
      line-height: 0.925rem;
    }

    // promo type 6
    &.promo_percent {
      font-size: 1.4rem;
      font-weight: 800;
      .small {
        font-size: 1rem;
      }
      .promo-header {
        display: flex;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 900;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }
  }
  // triangle
  .promo-box:not(.promo_ottienipunti):before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 4px;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-top-color: $promo-red;
    border-bottom: 0;
    border-right: 0;
    margin-right: -4px;
    margin-bottom: -8px;
  }
  .promo-box.promo_sotto:before {
    border-top-color: $promo-light-blue;
  }

  .promo-body {
    display: flex;
    justify-content: flex-end;
    .borsellino-img {
      width: 35px;
      height: 34px;
      text-align: right;
    }
  }
  .promo-footer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
    .card-img {
      max-width: 44px;
    }
  }
}
</style>
<script>
export default {
  name: "ProductPromo",
  props: {
    warehousePromo: { type: Object, required: true }
  }
};
</script>
