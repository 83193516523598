<template>
  <v-hover v-slot="{ hover }">
    <v-card
      rounded="lg"
      :href="link"
      outlined
      elevation="3"
      @click.prevent="handleLink"
      class="proposal-btn"
      :style="cardStyle"
      :class="{ 'is-hovering': hover }"
    >
      <div
        :style="hover ? getIconSectionStyle(hover) : getIconSectionStyle(hover)"
        class="img-container rounded-lg"
      >
        <img
          :src="proposal.img"
          :alt="proposal.imgAlt"
          :title="proposal.imgDescription"
          class="proposal-btn-img"
        />
        <!-- :style="
            hover
              ? ''
              : `fill: ${$ebsn.meta(
                  proposal,
                  'metaData.category_proposal_type.BG_ICON_COLOR'
                )}`
          " -->

        <!-- <object
          :src="proposal.img"
          width="100"
          height="100"
          type="image/xml+svg"
        >
        </object> -->
      </div>
      <div
        class="btn-label"
        v-if="proposal.content"
        v-html="proposal.content"
      ></div>
    </v-card>
  </v-hover>
</template>
<style lang="scss">
svg {
  fill: inherit;
}
path {
  fill: inherit;
}
.proposal-btn {
  // width: 108px;

  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 110px;
  .img-container {
    width: 100%;
    text-align: center;
    padding: 8px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    position: relative;
    .proposal-btn-img {
      width: 54px;
      height: "auto";
      max-height: 100%;
      filter: invert(52%) sepia(30%) saturate(6479%) hue-rotate(105deg)
        brightness(91%) contrast(85%);
    }
  }
  &.is-hovering {
    .img-container {
      transition: 0.5s all ease-in-out;
      .proposal-btn-img {
        filter: none;
        transition: 0.2s all ease-in-out;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top-color: inherit;
        border-bottom: 0;
        border-right: 0;
        margin-right: -5px;
        margin-bottom: -10px;
      }
    }
  }
  .btn-label {
    padding: 8px;
    overflow: hidden;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 0.875rem;
    max-width: 100%;
    text-align: center;
    @media (max-width: 320px) {
      white-space: normal;
      text-align: center;
    }
  }
}
</style>
<script>
import Vue from "vue";
import banner from "~/mixins/banner";
export default {
  name: "ProposalButton",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    cardStyle() {
      let style = {};
      let backgroundColor = Vue.$ebsn.meta(
        this.proposal,
        "metaData.category_proposal_type.BG_COLOR",
        "var(--v-white-base)"
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      let borderColor = Vue.$ebsn.meta(
        this.proposal,
        "metaData.category_proposal_type.BG_ICON_COLOR",
        "var(--v-primary-base)"
      );
      if (borderColor) {
        style.borderColor = borderColor;
      }

      return style;
    }
  },
  methods: {
    getIconSectionStyle(isHovering) {
      let style = {};
      let backgroundColor = Vue.$ebsn.meta(
        this.proposal,
        "metaData.category_proposal_type.BG_ICON_COLOR",
        "var(--v-white-base)"
      );
      if (backgroundColor) {
        if (isHovering) {
          style.backgroundColor = backgroundColor;
          style.borderTopColor = backgroundColor;
        } else {
          style.borderTopColor = "transparent";
        }
      }
      return style;
    }
  }
};
</script>
