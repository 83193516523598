<template>
  <div
    :id="`banner-list-${config.categoryBlockId}`"
    class="banner-list"
    :style="style"
    @click="clicked"
  >
    <ebsn-style
      :target="config"
      path="metaData.categoryblocktype_BannerList.CSS_STYLE"
      :styleWrapper="`#banner-list-${config.categoryBlockId}`"
    />
    <div :class="{ 'container container--fluid': container }">
      <ebsn-meta
        :target="config"
        path="categoryblocktype_BannerList.TITLE"
        tag="h2"
        :style="
          `color: ${$ebsn.meta(
            this.config,
            'categoryblocktype_BannerList.TITLE_COLOR',
            'currentColor'
          )}; `
        "
      />
      <ebsn-meta
        :target="config"
        path="categoryblocktype_BannerList.DESCRIPTION"
        tag="div"
      />

      <v-row>
        <v-col
          v-for="(proposal, index) in config.proposals"
          :key="proposal.id"
          :cols="getColData(index, 'xs')"
          :sm="getColData(index, 'sm')"
          :md="getColData(index, 'md')"
          :lg="getColData(index, 'lg')"
          :xl="getColData(index, 'xl')"
        >
          <component
            :is="getProposalModelName(proposal)"
            :proposal="proposal"
            class="h-100"
            :class="`banner-num-${index}`"
            :smallCard="true"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import ProposalImage from "./elements/ProposalImage.vue";
import ProposalCardHorizontalResponsive from "./elements/ProposalCardHorizontalResponsive.vue";
import ProposalCardOverlay from "./elements/ProposalCardOverlay.vue";
import ProposalCardVerticalResponsive from "./elements/ProposalCardVerticalResponsive.vue";
import ProposalButton from "./elements/ProposalButton.vue";

import clickHandler from "~/mixins/clickHandler";
import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import get from "lodash/get";

export default {
  name: "BannerList",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  components: {
    ProposalImage,
    ProposalCardHorizontalResponsive,
    ProposalCardOverlay,
    ProposalCardVerticalResponsive,
    ProposalButton
  },
  mixins: [deliveryReactive, categoryBlockType, clickHandler],
  data() {
    return {};
  },
  computed: {
    rows() {
      return get(
        this.config,
        "metaData.categoryblocktype_BannerList.JSON_CONFIG.rows",
        [{ xs: "12", sm: "6", md: "4", lg: "4", xl: "4" }]
      );
    }
  },
  methods: {
    getColData(index, fieldPath) {
      if (index < this.rows.length) {
        return get(this.rows[index], fieldPath, 12);
      }
      return get(this.rows[0], fieldPath, 12);
    }
  }
};
</script>
